import { useAuth0 } from "@auth0/auth0-react";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import lodash from "lodash";
import {
  Button,
  Card,
  FormGroup,
  PageTitle,
  Select,
  TextInput,
} from "src/components";
import Axios from "src/services/api";
import styles from "./Profile.module.scss";
import { currencies } from "src/services/currency";
import countries from "i18n-iso-countries";
import { Countries } from "../Customers/Customers";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countryOptions = Object.entries(countries.getNames("en")).map(
  ([code, name]) => ({ value: code, label: name })
);

const Settings = () => {
  const [me, setMe] = useState<any>(
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!)
  );

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const [countries, setCountries] = useState<Countries>({});

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  const [defaultUserData, setDefaultUserData] = useState({
    name: me?.name || "",
    firstName: me?.firstName || "",
    lastName: me?.lastName || "",
    city: me?.city || "",
    url: me?.url || "",
    contactEmail: me?.contactEmail || "",
    currency: me?.currency || "",
    country: me?.country || "",
    zipCode: me?.zipCode || "",
    state: me?.state || "",
    subdomain: me?.subdomain || "",
  });

  const [userData, setUserData] = useState(defaultUserData);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [succeeded, setSucceeded] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [failedChar, setFailedChar] = React.useState<boolean>(false);
  const [failedCharLength, setFailedCharLength] =
    React.useState<boolean>(false);

  const verifySubdomain = (subdomain: string) => {
    setLoading(true);
    setFailed(false);
    setFailedChar(false);
    setFailedCharLength(false);
    setSucceeded(false);

    Axios.get(`/users/verify-subdomain/${subdomain.toLowerCase()}`)
      .then(() => {
        setLoading(false);
        setSucceeded(true);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          setFailedChar(error.response.data.message === "char");
          setFailedCharLength(error.response.data.message === "length");
          setFailed(error.response.data.message === "failed");
        }
      });
  };

  const debouncedVerifySubdomain = lodash.debounce(verifySubdomain, 1000);

  useEffect(() => {
    if (!me) return;

    if (me.subdomain === userData.subdomain) {
      setFailed(false);
      setFailedChar(false);
      setFailedCharLength(false);
      setSucceeded(false);
      setLoading(false);
      return;
    }

    if (!userData.subdomain) {
      setFailed(false);
      setFailedChar(false);
      setFailedCharLength(false);
      setSucceeded(false);
      setLoading(false);
      return;
    }

    if (userData.subdomain.includes(" ")) {
      userData.subdomain = userData.subdomain.replace(/\s/g, "");
    } else {
      setLoading(true);
      setFailed(false);
      setFailedChar(false);
      setFailedCharLength(false);
      setSucceeded(false);
    }
    debouncedVerifySubdomain(userData.subdomain);

    return () => {
      debouncedVerifySubdomain.cancel();
    };
  }, [userData.subdomain]);

  const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    toast.promise(
      Axios.post(`/users/update`, {
        ...userData,
        subdomain: userData.subdomain.toLowerCase(),
      }).then((res) => {
        setMe(res.data);
        setDefaultUserData(userData);
        localStorage.setItem("me", JSON.stringify(res.data));
        window.location.reload();
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error while saving.",
      }
    );
  };

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (!userData.firstName) {
      formErrors.firstName = "First name is required";
    }
    if (!userData.lastName) {
      formErrors.lastName = "Last name is required";
    }
    if (!userData.contactEmail) {
      formErrors.contactEmail = "Support email is required";
    }
    if (!userData.subdomain) {
      formErrors.subdomain = "Username is required";
    }
    if (!userData.currency) {
      formErrors.currency = "Currency is required";
    }
    if (!userData.country) {
      formErrors.country = "Country is required";
    }
    if (!userData.zipCode) {
      formErrors.zipCode = "Zip code is required";
    }
    if (!userData.state) {
      formErrors.state = "State is required";
    }
    if (!userData.city) {
      formErrors.city = "City is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <form onSubmit={submitForm}>
      <div className={styles.settingsPage}>
        <PageTitle
          actions={
            <>
              <Button
                text={"Cancel"}
                variant="secondary"
                onClick={() => setUserData(defaultUserData)}
              />
              <Button
                text={"Save changes"}
                type="submit"
                disabled={lodash.isEqual(userData, defaultUserData)}
              />
            </>
          }
        >
          Settings
        </PageTitle>
        <Card>
          <div className={styles.formsContainer}>
            <FormGroup label="Change avatar">
              <AvatarUploader me={me} />
            </FormGroup>
            <div className={styles.formGrid}>
              <FormGroup label="First name" error={errors.firstName} required>
                <TextInput
                  value={userData.firstName}
                  name="firstName"
                  onChange={(e: any) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                  error={errors.firstName}
                />
              </FormGroup>
              <FormGroup label="Last name" error={errors.lastName} required>
                <TextInput
                  value={userData.lastName}
                  name="lastName"
                  onChange={(e: any) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                  error={errors.lastName}
                />
              </FormGroup>
            </div>
            <div className={styles.usernameHolder}>
              <FormGroup label="Username" error={errors.subdomain} required>
                <TextInput
                  value={userData.subdomain}
                  name="subdomain"
                  onChange={(e: any) =>
                    setUserData({ ...userData, subdomain: e.target.value })
                  }
                  error={errors.subdomain}
                  placeholder="e.g. johndoe"
                />
              </FormGroup>
              <div className={styles.suffix}>.pocketsflow.com</div>
              {loading && <div className={styles.loading}>Loading...</div>}
              {succeeded && (
                <div className={styles.succeeded}>It's yours 🎉</div>
              )}
              {(failed || failedChar || failedCharLength) && (
                <div className={styles.failed}>
                  {failed
                    ? "This username is already taken 😔"
                    : failedChar
                    ? "Invalid characters 😖"
                    : "Must be 3 characters and up 🙁"}
                </div>
              )}
            </div>
            <FormGroup
              label="Support email"
              error={errors.contactEmail}
              required
            >
              <TextInput
                value={userData.contactEmail}
                name="contactEmail"
                onChange={(e: any) =>
                  setUserData({ ...userData, contactEmail: e.target.value })
                }
                error={errors.contactEmail}
              />
            </FormGroup>
            {/* <FormGroup label="Currency" error={errors.currency} required>
              <Select
                options={currencies}
                selectedValue={userData.currency}
                error={errors.currency}
                disabled={me?.gotFirstSale}
                onChange={(value) =>
                  setUserData({ ...userData, currency: value })
                }
              />
            </FormGroup> */}
            <div className={styles.formGrid}>
              <FormGroup label="City" error={errors.city} required>
                <TextInput
                  value={userData.city}
                  name="city"
                  onChange={(e: any) =>
                    setUserData({ ...userData, city: e.target.value })
                  }
                  error={errors.city}
                />
              </FormGroup>
              <FormGroup label="State/Province" error={errors.state} required>
                <TextInput
                  value={userData.state}
                  name="state"
                  onChange={(e: any) =>
                    setUserData({ ...userData, state: e.target.value })
                  }
                  error={errors.state}
                />
              </FormGroup>
            </div>
            <div className={styles.formGrid}>
              <FormGroup label="Zip code" error={errors.zipCode} required>
                <TextInput
                  value={userData.zipCode}
                  name="zipCode"
                  onChange={(e: any) =>
                    setUserData({ ...userData, zipCode: e.target.value })
                  }
                  error={errors.zipCode}
                />
              </FormGroup>
              <FormGroup label="Country" error={errors.country} required>
                <Select
                  options={countryOptions}
                  selectedValue={userData.country}
                  error={errors.country}
                  onChange={(value) =>
                    setUserData({ ...userData, country: value })
                  }
                />
              </FormGroup>
            </div>
          </div>
        </Card>
      </div>
    </form>
  );
};

export const AvatarUploader = ({ me }: any) => {
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarPreview, setAvatarPreview] = useState<string | null>(
    me?.picture || null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/gif"];
    const MAX_SIZE = 2 * 1024 * 1024; // 2MB

    if (file && ALLOWED_TYPES.includes(file.type) && file.size <= MAX_SIZE) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setAvatarPreview(reader.result as string);
      };

      reader.readAsDataURL(file);
      setAvatar(file);
      handleUpload(file);
    } else {
      alert("Invalid file. Only JPG, PNG, or GIF files under 2MB are allowed.");
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = async (file: File) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      toast.promise(
        Axios.post("/users/avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          window.location.reload();
        }),
        {
          loading: "Uploading avatar...",
          success: "Avatar uploaded!",
          error: "Error uploading avatar.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.avatarContainer}>
      <div className={styles.avatarPreview}>
        {avatarPreview ? <img src={avatarPreview} alt="Avatar" /> : "No Image"}
      </div>
      <div className={styles.avatarText}>
        <Button text="Select Image" onClick={handleUploadClick} />
        <input
          ref={fileInputRef}
          className={styles.fileInput}
          type="file"
          accept="image/png, image/jpeg, image/gif"
          onChange={handleImageUpload}
        />
        {/* <button className={styles.uploadButton} onClick={handleUpload}>
        Upload
      </button> */}
        <span>Accepts JPG, PNG, or GIF. 2MB MAX</span>
      </div>
    </div>
  );
};

export default Settings;
