import { Button, Modal, TextInput } from "src/components";
import styles from "./ShareModal.module.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ShareModal = ({ created, product, onClose, isSubscription }: any) => {
  const navigate = useNavigate();
  const [choice, setChoice] = useState<"product" | "checkout">(
    product?.hasProductPage === "yes" ? "product" : "checkout"
  );
  const onCloseModal = () => {
    onClose
      ? onClose()
      : navigate("/products/" + product._id, { replace: true });
  };
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  const link = isSubscription
    ? `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout?subscriptionId=${product._id}`
    : choice === "product"
    ? `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product._id}`
    : `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout?productId=${product._id}`;

  const title = isSubscription ? "Share subscription" : "Share product";

  return (
    <Modal onClose={onCloseModal} title={title} noFooter>
      <div className={styles.shareModal}>
        {!isSubscription && (
          <div className={styles.tabs}>
            <div
              className={`${styles.tab} ${
                choice === "product" ? styles.active : ""
              } ${product?.hasProductPage !== "yes" ? styles.disabled : ""}`}
              onClick={() => setChoice("product")}
            >
              Product page
            </div>
            <div
              className={`${styles.tab} ${
                choice === "checkout" ? styles.active : ""
              }`}
              onClick={() => setChoice("checkout")}
            >
              Checkout page
            </div>
          </div>
        )}
        <div className={styles.shareModalContent}>
          <div className={styles.deleteModalText}>
            Copy the link and share it with the world.
          </div>
          <div className={styles.copyLinkContainer}>
            <TextInput
              type="text"
              value={link}
              onChange={() => {}}
              name="copyLink"
              disabled
            />
            <Button
              variant="secondary"
              text="Copy link"
              onClick={() => {
                // copy to clipboard
                navigator.clipboard.writeText(link);
                toast.success("copied");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
