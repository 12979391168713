import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Axios from "src/services/api";
import styles from "./ProductSale.module.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { makeCdnUrl } from "src/services/cdn";

import { Page1 } from "./ProductPages";
import { PageLoader } from "src/components/page-loader";
import { toast } from "react-hot-toast";
import { Page2 } from "./ProductPages/Page2";
import { Page3 } from "./ProductPages/Page3";
import { EmptyState } from "src/components";

const ProductSale = ({ id, prod, _user, vars, demoMode }: any) => {
  const currentSubdomain = window.location.hostname.split(".")[0];

  const navigate = useNavigate();
  const [product, setProduct] = useState<any>(null);
  const [variants, setVariants] = useState<any[]>(vars ?? []);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [user, setUser] = useState<any>(_user);
  const routeParams = useParams<{ id: string }>();
  const productId = id || routeParams.id?.split("&")[0];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get("view");
  const affiliate = queryParams.get("a");

  const [country, setCountry] = useState<string>("US");
  const [zip, setZip] = useState<string>("0000");
  const [state, setState] = useState<string>("Texas");
  const [city, setCity] = useState<string>("Houston");
  const [regionCode, setRegionCode] = useState<string>("TX");
  const [currency, setCurrency] = useState<string>("USD");
  const [ip, setIp] = useState<string>("");

  const [loadingImages, setLoadingImages] = useState(!demoMode);

  const [reviews, setReviews] = useState<any>([]);
  const [salesNumber, setSalesNumber] = useState<number>(0);

  const design = product?.design;

  useEffect(() => {
    if (!product || !product.published) return;

    console.log(product.hasProductPage);

    if (!product.hasProductPage || product.hasProductPage === "unknown") {
      navigate(`/checkout?productId=${productId}`);
    }
  }, [product]);

  useEffect(() => {
    if (!prod && productId) {
      Axios.post(`/dashboard/visitor`, {
        productId: productId,
        page: window.location.pathname,
      })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (product && !prod) {
      Axios.get(`/reviews/${product._id}`).then((res) => {
        setReviews(res.data);
      });

      if (product.showSales) {
        Axios.get(`/sales/product/${product._id}`).then((res) => {
          setSalesNumber(res.data.length);
        });
      }

      if (!productId) return;

      Axios.get(
        "/products/verify-subdomain/" + currentSubdomain + "/" + productId
      )
        .then(() => {
          console.log("subdomain verified");
        })
        .catch((error) => {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
            navigate("/");
          }
        });
    }
  }, [product]);

  useEffect(() => {
    if (_user || !product?.userId) return;

    Axios.get(`/users/userId/${product.userId}`).then((res) => {
      setUser(res.data);
    });
  }, [product]);

  const [searchParams] = useSearchParams();

  const [webhookMetadata, setWebhookMetadata] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const customParams: Record<string, string> = {};

    // Extract only custom parameters
    searchParams.forEach((value, key) => {
      // if (!knownParams.has(key)) {
      customParams[key] = value;
      // }
    });

    // Update the state with custom parameters
    setWebhookMetadata(customParams);

    // Log for debugging
    console.log("Webhook Metadata (custom params only):", customParams);
  }, [searchParams]);

  useEffect(() => {
    if (demoMode) {
      let htmlContent = prod.description;
      const youtubeUrls = htmlContent?.match(/<video src="([^"]*)"><\/video>/g); // Extract <video> tags with YouTube URLs

      youtubeUrls?.forEach((videoTag: any) => {
        const youtubeUrl = videoTag.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
        const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

        // Generate iframe equivalent
        const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

        htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
      });

      const borderColor = prod.colors.find(
        (c: any) => c.id === "borders"
      )?.value;

      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );
      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );
      htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

      setProduct({
        ...prod,
        description: htmlContent,
      });

      return;
    }

    const timeoutId = setTimeout(() => {
      if (prod) {
        let htmlContent = prod.description;
        const youtubeUrls = htmlContent?.match(
          /<video src="([^"]*)"><\/video>/g
        ); // Extract <video> tags with YouTube URLs

        youtubeUrls?.forEach((videoTag: any) => {
          const youtubeUrl = videoTag.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
          const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

          // Generate iframe equivalent
          const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

          htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
        });

        const borderColor = prod.colors.find(
          (c: any) => c.id === "borders"
        )?.value;

        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );
        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );
        htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

        setProduct({
          ...prod,
          description: htmlContent,
        });
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [prod, id]);

  // from this product id, get the product user, and get the stripe account id

  useEffect(() => {
    if (productId === "Dummy") return;

    if (demoMode) return;

    Axios.get(`/products/prod/${productId}`).then((res) => {
      let htmlContent = res.data.description;
      const youtubeUrls =
        htmlContent?.match(/<video src="([^"]*)"><\/video>/g) || []; // Extract <video> tags with YouTube URLs
      youtubeUrls?.forEach((videoTag: any) => {
        const youtubeUrl = videoTag.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
        const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

        // Generate iframe equivalent
        const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

        htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
      });

      const borderColor = res.data.colors.find(
        (c: any) => c.id === "borders"
      )?.value;

      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );
      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );
      htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

      setProduct({ ...res.data, description: htmlContent });
      Axios.get(`/images/product/${res.data._id}`)
        .then((resImages) => {
          setProduct({
            ...res.data,
            description: htmlContent,
            images: resImages.data,
          });
          // if (!prod) {
          // Axios.get("https://api.ipgeolocation.io/ipgeo", {
          //   params: {
          //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
          //   },
          // }).then((resIQ) => {
          //   const country = resIQ.data.country_code2;
          //   const zip = resIQ.data.postal;
          //   const state = resIQ.data.region;
          //   const stateCode = resIQ.data.region_code;
          //   const city = resIQ.data.city;
          //   const ip = resIQ.data.ip;

          //   setCountry(country);
          //   setZip(zip);
          //   setState(state);
          //   setCity(city);
          //   setRegionCode(stateCode);
          //   setIp(ip);

          //   const _currency = resIQ.data.currency.code;

          // });

          // Axios.get(`/products/ppp/${res.data._id}`).then((resPPP) => {
          //   setCurrency(resPPP.data.currency);
          //   setProduct({
          //     ...res.data,
          //     description: htmlContent,
          //     images: resImages.data,
          //     price: resPPP.data.price,
          //     minPrice: resPPP.data.minPrice,
          //     maxPrice: resPPP.data.maxPrice,
          //   });
          // });
          // }
        })
        .finally(() => {
          setLoadingImages(false);
        });
    });
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  // create payment intent
  const buyProduct = async () => {
    setLoading(true);
    if (
      (product.price === 0 &&
        !product.payWant &&
        (selectedVariant?.price === 0 ||
          selectedVariant?.price === undefined)) ||
      (payWantPrice === 0 &&
        product.payWant &&
        (selectedVariant?.price === 0 ||
          selectedVariant?.price === undefined)) ||
      !user.stripeConnectedAccountId
    )
      return navigate(
        affiliate
          ? `/checkout?productId=${productId}&a=${affiliate}`
          : `/checkout?productId=${productId}`
      );

    try {
      const _payWantPrice =
        !payWantPrice || (payWantPrice as unknown as string) === ""
          ? 0
          : payWantPrice;
      const res = await Axios.post("/products/create-payment-intent", {
        productId,
        country,
        zip,
        state,
        city,
        regionCode,
        currency,
        ip,
        affiliate,

        payWantPrice: _payWantPrice,

        variantId: selectedVariant?._id,

        webhookMetadata,
      });

      setLoading(false);

      const serializedMetadata = Object.entries(webhookMetadata)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      navigate(
        affiliate
          ? `/checkout/${res.data.clientSecret}/${
              res.data.paymentIntentId
            }?productId=${productId}&variantId=${
              selectedVariant?._id
            }&a=${affiliate}&pw=${_payWantPrice}&c=${currency}${
              webhookMetadata ? `&${serializedMetadata}` : ""
            }`
          : `/checkout/${res.data.clientSecret}/${
              res.data.paymentIntentId
            }?productId=${productId}&variantId=${
              selectedVariant?._id
            }&pw=${_payWantPrice}&c=${currency}${
              webhookMetadata ? `&${serializedMetadata}` : ""
            }`
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error((error as any).response.data.message);
    }
  };

  const [payWantPrice, setPayWantPrice] = useState<number>(0);

  useEffect(() => {
    if (!product) return;
    if (product.payWant && product.minPrice) {
      setPayWantPrice(product.minPrice || product.maxPrice);
    }
  }, [product]);

  useEffect(() => {
    if (product?._id) {
      Axios.get(`/products/${product?._id}/variants`).then((res) => {
        setVariants(res.data);
        setSelectedVariant(null);
      });
    }
  }, [product]);

  if (!product) return <PageLoader />;

  if (!id && view !== "true" && product.published === false && !prod)
    return <EmptyState title="Product is not published"></EmptyState>;

  const footerColor = {
    color: isLight(product.colors.find((c: any) => c.id === "background").value)
      ? "#000"
      : "#fff",
  };

  return (
    <HelmetProvider>
      {!prod && (
        <Helmet>
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={product.name} />
          <meta
            property="og:description"
            content={product.description?.replace(/<[^>]+>/g, "")}
          />
          <meta
            property="og:image"
            content={
              demoMode
                ? product.thumbnail || product?.images[0]?.url
                : makeCdnUrl(product.thumbnail || product?.images[0]?.url)
            }
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content={product.name} />
          <meta
            property="twitter:description"
            content={product.description?.replace(/<[^>]+>/g, "")}
          />
          <meta
            property="twitter:image"
            content={
              demoMode
                ? product.thumbnail || product?.images[0]?.url
                : makeCdnUrl(product.thumbnail || product?.images[0]?.url)
            }
          />
        </Helmet>
      )}
      <div
        className={`${styles.pageContainer} ${prod ? styles.fromProdPage : ""}`}
        style={{
          background: product.colors.find((c: any) => c.id === "background")
            .value,
        }}
        id="pageContainer"
        key={selectedVariant?.length || "noVariants"}
      >
        {design === 1 ? (
          <Page1
            variants={variants}
            product={product}
            loading={loading}
            buyProduct={buyProduct}
            currency={"USD"}
            reviews={reviews}
            salesNumber={salesNumber}
            payWantPrice={payWantPrice}
            setPayWantPrice={setPayWantPrice}
            user={user}
            demoMode
            loadingImages={loadingImages}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
        ) : design === 2 ? (
          <Page2
            variants={variants}
            product={product}
            loading={loading}
            buyProduct={buyProduct}
            currency={"USD"}
            reviews={reviews}
            salesNumber={salesNumber}
            payWantPrice={payWantPrice}
            setPayWantPrice={setPayWantPrice}
            user={user}
            demoMode
            loadingImages={loadingImages}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
        ) : design === 3 ? (
          <Page3
            variants={variants}
            product={product}
            loading={loading}
            buyProduct={buyProduct}
            currency={"USD"}
            reviews={reviews}
            salesNumber={salesNumber}
            payWantPrice={payWantPrice}
            setPayWantPrice={setPayWantPrice}
            user={user}
            demoMode
            loadingImages={loadingImages}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
        ) : null}
        <div className={styles.footer} style={footerColor}>
          Powered by{" "}
          <a
            href="https://www.pocketsflow.com/"
            target="_blank"
            style={footerColor}
          >
            Pocketsflow
          </a>
        </div>
      </div>
    </HelmetProvider>
  );
};

export function hexToRgb(hex: any) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function calculateLuminance(r: any, g: any, b: any) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

export function isLight(hex: any, threshold = 0.5) {
  const rgb = hexToRgb(hex);
  if (!rgb) return null;
  const luminance = calculateLuminance(rgb.r, rgb.g, rgb.b);
  return luminance > threshold;
}

export default ProductSale;
