import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./SubscriptionPortal.module.scss";
import Axios from "src/services/api";
import { Button } from "src/components";
import {
  MdCreditCard,
  MdDelete,
  MdDeleteOutline,
  MdEdit,
  MdModeEditOutline,
} from "react-icons/md";
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
} from "react-icons/fa";
import { PageLoader } from "src/components/page-loader";

const SubscriptionPortal = () => {
  const { subscriptionId, subscriptionCustomerId } = useParams();

  const [subscription, setSubscription] = useState<any>();
  const [invoices, setInvoices] = useState<any[]>();
  const [stripeSubscriptionPaymentMethod, setStripeSubscriptionPaymentMethod] =
    useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(
      `/subscriptions/sub/portal/${subscriptionId}/${subscriptionCustomerId}`
    )
      .then((res) => {
        console.log(res.data);
        setIsLoading(true);
        window.location.href = res.data.session.url;
        // setStripeSubscriptionPaymentMethod(
        //   res.data.stripeSubscriptionPaymentMethod
        // );
        // Axios.get(`/subscriptions/sub/invoices/${stripeSubscriptionId}`)
        //   .then((res) => {
        //     setInvoices(res.data);
        //     setIsLoading(false);
        //   })
        //   .catch(() => {
        //     setIsLoading(false);
        //   });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <PageLoader />;

  return <></>;
};

const getCardName = (cardType: any) => {
  switch (cardType?.toLowerCase()) {
    case "visa":
      return "Visa";
    case "mastercard":
      return "Mastercard";
    case "amex":
      return "American Express";
    case "discover":
      return "Discover";
    case "diners":
      return "Diners Club";
    case "jcb":
      return "JCB";
    case "unionpay":
      return "UnionPay";
    case "eftpos_au":
      return "Eftpos AU";
    case "link":
      return "Link";
    default:
      return "Unknown Card";
  }
};

const getCardIcon = (cardType: any) => {
  switch (cardType?.toLowerCase()) {
    case "visa":
      return <FaCcVisa size={24} style={{ color: "#1A1F71" }} />;
    case "mastercard":
      return <FaCcMastercard size={24} style={{ color: "#EB001B" }} />;
    case "amex":
      return <FaCcAmex size={24} style={{ color: "#2E77BC" }} />;
    case "discover":
      return <FaCcDiscover size={24} style={{ color: "#F5811F" }} />;
    case "diners":
      return <FaCcDinersClub size={24} style={{ color: "#006BA6" }} />;
    case "jcb":
      return <FaCcJcb size={24} style={{ color: "#D71429" }} />;
    case "unionpay":
      return <MdCreditCard size={24} style={{ color: "#41B649" }} />;
    case "eftpos_au":
      return <MdCreditCard size={24} style={{ color: "#555555" }} />; // Custom color for eftpos_au
    case "link":
      return <MdCreditCard size={24} style={{ color: "#555555" }} />; // Custom color for link
    default:
      return <MdCreditCard size={24} style={{ color: "#888888" }} />; // Generic fallback
  }
};

export default SubscriptionPortal;
