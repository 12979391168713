import React, { useEffect } from "react";
import { AuthenticationGuard, PageLayout } from "./components";
import { Route, Routes, useLocation } from "react-router-dom";
import { Callback } from "./pages/Callback";

import { ProductSale } from "./pages/Sales/ProductSale";
import { Checkout } from "./pages/Sales/Checkout";
import { CheckoutSuccess } from "./pages/Sales/CheckoutSuccess";
import { Creator } from "./pages/Sales/Creator";
import { VerifiedEmail } from "./pages/VerifiedEmail";
import { RefundPolicy } from "./pages/Sales/RefundPolicy";
import Invoice from "./pages/Order/Invoice";

import { AddWebhook } from "./pages/Developers/routes/Webhooks";
import SubscriptionPortal from "./pages/Sales/SubscriptionPortal";

// http://localhost:4040/checkout?subscriptionId=675f026c75d35b6259cf09f3

const IS_TESTING_MODE = false;

export const App: React.FC = () => {
  const location = useLocation();
  const currentSubdomain = window.location.hostname.split(".")[0];

  const appRoutes = (
    <>
      <Route path="/callback" element={<Callback />} />
      <Route path="/invoice/:id" element={<Invoice />} />
      <Route path="/verify" element={<VerifiedEmail />} />
      <Route path="/developers/webhooks/add" element={<AddWebhook />} />
      <Route path="/developers/webhooks/:id" element={<AddWebhook />} />
      <Route
        path="/*"
        element={<AuthenticationGuard component={PageLayout} />}
      />
    </>
  );

  const condition = IS_TESTING_MODE
    ? false
    : process.env.REACT_APP_ENV !== "production";

  useEffect(() => {
    // @ts-ignore
    window.Intercom("update");
  }, [location]);

  return (
    <Routes>
      {condition ? (
        appRoutes
      ) : currentSubdomain === "app" ? (
        appRoutes
      ) : (
        <>
          <Route path="/:id" element={<ProductSale />} />
          <Route
            path="/checkout/:clientSecret/:paymentIntentId"
            element={<Checkout />}
          />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/refund-policy/:prodId" element={<RefundPolicy />} />
          <Route path="/checkout/success" element={<CheckoutSuccess />} />
          <Route
            path="/portal/:subscriptionId/:subscriptionCustomerId"
            element={<SubscriptionPortal />}
          />
          <Route path="/" element={<Creator />} />
        </>
      )}
    </Routes>
  );
};
