import React from "react";
import styles from "./FormGroup.module.scss";

type FormGroupProps = {
  children: React.ReactNode;
  label: React.ReactNode | string;
  action?: React.ReactNode;
  error?: string;
  required?: boolean;
  small?: boolean;
  inline?: boolean;
  color?: string;
  dummy?: boolean;
  reversed?: boolean;
};

const FormGroup: React.FC<FormGroupProps> = ({
  dummy,
  children,
  label,
  error,
  action,
  required,
  small,
  inline,
  color,
  reversed,
}) => {
  return (
    <div
      className={
        styles.formGroup +
        " " +
        (inline ? styles.inline : "") +
        " " +
        (reversed ? styles.reversed : "")
      }
    >
      <div
        className={styles.labelContainer + " " + (dummy ? styles.dummy : "")}
      >
        <label
          className={styles.label + " " + (small ? styles.small : "")}
          style={
            color
              ? {
                  color: color,
                }
              : {}
          }
        >
          {label}
          {required && <span className={styles.required}>*</span>}
        </label>
        {action && <div className={styles.action}>{action}</div>}
      </div>
      {children}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default FormGroup;
