import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Axios from "src/services/api";
import { useNavigate } from "react-router-dom";

import { downloadFile } from "./utils";
import {
  Button,
  CreatorNavBar,
  FormGroup,
  SocialSharing,
  TextArea,
} from "src/components";
import styles from "./CheckoutSuccess.module.scss";
import "./CheckoutSuccess.scss";
import ConfettiComponent from "./Confetti";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-hot-toast";
import { PageLoader } from "src/components/page-loader";
import { Check, X } from "react-feather";
import { makeCdnUrl } from "src/services/cdn";

const CheckoutSuccess = ({ prod, _user, demoMode }: any) => {
  const currentSubdomain = window.location.hostname.split(".")[0];
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("paymentIntentId");
  const saleId = searchParams.get("saleId");
  const isSubscription =
    searchParams.get("isSubscription") || paymentIntentId?.includes("sub_");
  const redirectStatus = searchParams.get("redirect_status");
  const [isLoading, setIsLoading] = useState(true);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement | null>(null);

  const [product, setProduct] = useState<any>(null);
  const [variant, setVariant] = useState<any>(null);
  const [sale, setSale] = useState<any>(null);
  const [review, setReview] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState<string>("");

  // const [country, setCountry] = useState<string>("");
  // const [currency, setCurrency] = useState<string>("USD");

  const [polling, setPolling] = useState<any>(true);

  useEffect(() => {
    // ❌❌❌ Check thtis out
    if (demoMode && prod) {
      // let htmlContent = prod?.thankYouText;
      // const youtubeUrls =
      //   htmlContent?.match(/<video src="([^"]*)"><\/video>/g) || []; // Extract <video> tags with YouTube URLs

      // if (!youtubeUrls) {
      //   return;
      // }

      // youtubeUrls?.forEach((videoTag: any) => {
      //   const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
      //   const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

      //   // Generate iframe equivalent
      //   const iframeEquivalent = `
      //     <iframe
      //       width="560"
      //       height="315"
      //       src="https://www.youtube.com/embed/${youtubeId}"
      //       title="YouTube video player"
      //       frameborder="0"
      //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      //       allowfullscreen>
      //     </iframe>
      //   `;

      //   htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
      // });

      // const borderColor = prod?.colors.find(
      //   (c: any) => c.id === "borders"
      // )?.value;

      // htmlContent = htmlContent?.replace(
      //   /<hr>/g,
      //   `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      // );
      // htmlContent = htmlContent?.replace(
      //   /<hr>/g,
      //   `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      // );

      // htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

      setProduct({
        ...prod,
        thankYouText: "",
      });
      setIsLoading(false);
      setPolling(false);
    }

    // add sale to database
    if (redirectStatus === "succeeded") {
      let pollSale: any;
      const fetchSale = () => {
        if (isSubscription) {
          if (paymentIntentId) {
            console.log({ paymentIntentId });
            Axios.get("/sales/payment-intent/" + paymentIntentId)
              .then((res) => {
                Axios.post(`/dashboard/visitor-subscription`, {
                  subscriptionId: res.data.product?._id,
                  page: window.location.pathname,
                })
                  .then((res) => {
                    console.log(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                setSale(res.data.sale);
                setProduct({
                  ...res.data.product,
                  thankYouText: "",
                });
                setUser(res.data.user);
                setReview(undefined);
                setIsLoading(false);
                setPolling(false);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        } else {
          if (paymentIntentId) {
            Axios.get("/sales/payment-intent/" + paymentIntentId)
              .then((res) => {
                if (!prod)
                  Axios.post(`/dashboard/visitor`, {
                    productId: res.data.product?._id,
                    page: window.location.pathname,
                  })
                    .then((res) => {
                      console.log(res.data);
                    })
                    .catch((err) => {
                      console.log(err);
                    });

                console.log(res.data.sale.emailCallbackUrl);

                if (res.data.sale && res.data.sale.emailCallbackUrl) {
                  window.location.href = res.data.sale.emailCallbackUrl;
                }

                //       let htmlContent = res.data.product.thankYouText;
                //       const youtubeUrls =
                //         htmlContent?.match(/<video src="([^"]*)"><\/video>/g) || []; // Extract <video> tags with YouTube URLs

                //       if (!youtubeUrls) {
                //         return;
                //       }

                //       youtubeUrls?.forEach((videoTag: any) => {
                //         const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
                //         const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

                //         // Generate iframe equivalent
                //         const iframeEquivalent = `
                //   <iframe
                //     width="560"
                //     height="315"
                //     src="https://www.youtube.com/embed/${youtubeId}"
                //     title="YouTube video player"
                //     frameborder="0"
                //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                //     allowfullscreen>
                //   </iframe>
                // `;

                //         htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
                //       });

                //       const borderColor = res.data.product.colors.find(
                //         (c: any) => c.id === "borders"
                //       )?.value;

                //       htmlContent = htmlContent?.replace(
                //         /<hr>/g,
                //         `<hr style="border: none; border-top: 1px solid ${borderColor};">`
                //       );
                //       htmlContent = htmlContent?.replace(
                //         /<hr>/g,
                //         `<hr style="border: none; border-top: 1px solid ${borderColor};">`
                //       );

                //       htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

                setSale(res.data.sale);
                setProduct({
                  ...res.data.product,
                  thankYouText: "",
                });
                setUser(res.data.user);
                setReview(res.data.review);
                setIsLoading(false);
                setPolling(false);
              })
              .then(() => {
                Axios.get("https://api.ipgeolocation.io/ipgeo", {
                  params: {
                    apiKey: "5c920f814db94414b5f7f70bd9dd56de",
                  },
                }).then((resIQ) => {
                  const country = resIQ.data.country_code2;
                  Axios.post("/sales/update-sale/" + paymentIntentId, {
                    country,
                  }).then(() => {
                    console.log("country updated");
                  });
                });
              });
          } else if (saleId) {
            Axios.get("/sales/" + saleId).then((res) => {
              Axios.get("https://api.ipgeolocation.io/ipgeo", {
                params: {
                  apiKey: "5c920f814db94414b5f7f70bd9dd56de",
                },
              }).then((resIQ) => {
                const country = resIQ.data.country_code2;
                Axios.post("/sales/update-sale-by-id/" + saleId, {
                  country,
                }).then(() => {
                  console.log("country updated");
                });
              });

              if (!prod)
                Axios.post(`/dashboard/visitor`, {
                  productId: res.data.product?._id,
                  page: window.location.pathname,
                })
                  .then((res) => {
                    console.log(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              //     let htmlContent = res.data.product.thankYouText;
              //     const youtubeUrls =
              //       htmlContent?.match(/<video src="([^"]*)"><\/video>/g) || []; // Extract <video> tags with YouTube URLs
              //     if (!youtubeUrls) {
              //       return;
              //     }
              //     youtubeUrls?.forEach((videoTag: any) => {
              //       const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
              //       const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

              //       // Generate iframe equivalent
              //       const iframeEquivalent = `
              //   <iframe
              //     width="560"
              //     height="315"
              //     src="https://www.youtube.com/embed/${youtubeId}"
              //     title="YouTube video player"
              //     frameborder="0"
              //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              //     allowfullscreen>
              //   </iframe>
              // `;

              //       htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
              //     });

              //     const borderColor = res.data.product.colors.find(
              //       (c: any) => c.id === "borders"
              //     )?.value;

              //     htmlContent = htmlContent?.replace(
              //       /<hr>/g,
              //       `<hr style="border: none; border-top: 1px solid ${borderColor};">`
              //     );
              //     htmlContent = htmlContent?.replace(
              //       /<hr>/g,
              //       `<hr style="border: none; border-top: 1px solid ${borderColor};">`
              //     );

              //     htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

              setSale(res.data.sale);
              setProduct({
                ...res.data.product,
                thankYouText: "",
              });
              setVariant(res.data.variant || null);
              setUser(res.data.user);
              setReview(res.data.review);
              setIsLoading(false);
              setPolling(false);
            });
          }
        }
      };

      if (!sale) {
        fetchSale();
      }

      if (polling) {
        pollSale = setInterval(() => {
          fetchSale();
        }, 1000); // Poll every 1 second
      }

      return () => {
        clearInterval(pollSale);
      };
    }
    return;
  }, [sale]);

  useEffect(() => {
    setIsLoading(true);
    if (isSubscription && !!product) {
      const sub = product;
      Axios.get(`/subscriptions/sub/${sub?._id}/${paymentIntentId}`)
        .then((res) => {
          const hasCallbackUrl = res.data.subscription.hasCallbackUrl;
          const callbackUrl = res.data.subscription.callbackUrl;
          const subscriptionCustomer = res.data.subscriptionCustomer;
          // CHECK THE WEBHOOK
          console.log({ hasCallbackUrl });
          if (hasCallbackUrl) {
            window.location.href = `${callbackUrl}?subscriptionId=${sub?._id}&subscriptionCustomer=${subscriptionCustomer?._id}&redirect_status=succeeded`;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [isSubscription, product]);

  useEffect(() => {
    if (prod && _user) {
      setSale({
        id: 1,
        _id: 1,
        amount: prod.price,
        buyerEmail: "",
      });

      setProduct({
        ...prod,
        thankYouText: "",
      });
      setUser(_user);
      setIsLoading(false);
    }
  }, [prod, _user]);

  useEffect(() => {
    if (review) {
      setRating(review.rating);
      setReviewText(review.review);
    }
  }, [review]);

  useEffect(() => {
    if (divRef.current && product) {
      const downloadButtons = divRef.current.getElementsByClassName(
        "added-button download-button"
      );

      for (let i = 0; i < downloadButtons.length; i++) {
        downloadButtons[i].addEventListener("click", downloadProduct);
        downloadButtons[i].removeAttribute("href");
      }
    }
  }, [product]);

  const downloadProduct = async () => {
    if (variant) {
      if (variant.isFile) {
        downloadFile(variant.file, variant?.name);
      } else {
        // window.open(variant.url, "_blank");
        const a = document.createElement("a");
        const url = variant.url.startsWith("http")
          ? variant.url
          : `https://${variant.url}`;
        a.href = url;
        a.rel = "noopener noreferrer";
        a.click();
      }
    } else if (product.isFile) {
      downloadFile(product.file, product?.name);
    } else {
      // window.open(product.url, "_blank");
      const url = product.url.startsWith("http")
        ? product.url
        : `https://${product.url}`;
      const a = document.createElement("a");
      a.href = url;
      a.rel = "noopener noreferrer";
      a.click();
    }
  };

  const downloadUpsellProduct = async () => {
    if (upsell.upsellProduct.isFile) {
      downloadFile(upsell.upsellProduct.file, upsell.upsellProduct.name);
    } else {
      // window.open(upsell.upsellProduct.url, "_blank");
      const url = upsell.upsellProduct.url.startsWith("http")
        ? upsell.upsellProduct.url
        : `https://${upsell.upsellProduct.url}`;
      const a = document.createElement("a");
      a.href = url;
      a.rel = "noopener noreferrer";
      a.click();
    }
  };

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);

    // other logic
  };

  const confettiCount = 10;

  const [upsell, setUpsell] = useState<any>(null);
  useEffect(() => {
    if (sale && sale?._id && !_user) {
      Axios.get("/sales/upsell/" + sale?._id)
        .then((res) => {
          setUpsell(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [sale]);

  const [firstTimeProduct, setFirstTimeProduct] = useState<any>(true);

  useEffect(() => {
    if (product && firstTimeProduct && !_user) {
      // Axios.get("https://api.ipgeolocation.io/ipgeo", {
      //   params: {
      //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
      //   },
      // }).then((resIQ) => {
      //   const _currency = resIQ.data.currency.code;
      //   const country = resIQ.data.country_code2;

      //   setCountry(country);

      //   // Axios.get(`/products/ppp/${product._id}/${country}/${_currency}`).then(
      //   //   (resPPP) => {
      //   //     setCurrency(resPPP.data.currency);
      //   //     setPrice(resPPP.data);
      //   //     setFirstTimeProduct(false);
      //   //   }
      //   // );
      //   Axios.get(`/sales/currencyConversion/${sale._id}/${_currency}`).then(
      //     (resPPP) => {
      //       // setCurrency(_currency);
      //       setPrice(resPPP.data);
      //       setFirstTimeProduct(false);
      //     }
      //   );
      // });

      if (!product?._id) return;

      Axios.get(
        (isSubscription
          ? "/subscriptions/verify-subdomain/"
          : "/products/verify-subdomain/") +
          currentSubdomain +
          "/" +
          product?._id
      )
        .then(() => {
          console.log("subdomain verified");
          setFirstTimeProduct(false);
        })
        .catch((error) => {
          setFirstTimeProduct(false);
          if (error.response.data.message) {
            toast.error(error.response.data.message);
            navigate("/");
          }
        });
    }
  }, [product, upsell]);

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  // if sale.buyerEmail is not equal to user.email open sign up popup after 2 seconds
  useEffect(() => {
    setTimeout(() => {
      if (
        !me ||
        (sale && sale.buyerEmail && me && me.email !== sale.buyerEmail)
      ) {
        localStorage.setItem("product-bought", JSON.stringify(product));
        setShowSignupPopup(true);
      }

      if (sale && sale.buyerEmail && me && me.email === sale.buyerEmail) {
        // user logged in and saved, then save product in bought products

        Axios.post(
          "/bought-products",
          {
            product,
            buyerUser: me.userId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
      }
    }, 2000);
  }, [sale, me]);

  const onClose = () => {
    setShowSignupPopup(false);
  };

  const getThumbnail = (thumbnail?: string) => {
    if (demoMode) {
      return (
        product?.thumbnail || "https://dummyimage.com/1200&text=Product+Image"
      );
    }

    if (product?.thumbnail && product?.thumbnail !== "") {
      return makeCdnUrl(product?.thumbnail);
    } else if (product?.images?.length > 0) {
      return makeCdnUrl(product?.images[0]?.url);
    } else {
      return "https://dummyimage.com/1200&text=Product+Image";
    }
  };

  const getUpsellThumbnail = (thumbnail?: string) => {
    if (demoMode) {
      return (
        upsell?.upsellProduct.thumbnail ||
        "https://dummyimage.com/1200&text=Product+Image"
      );
    }

    if (upsell?.upsellProduct.thumbnail) {
      return makeCdnUrl(upsell?.upsellProduct.thumbnail);
    } else if (upsell?.upsellProduct.images?.length > 0) {
      return makeCdnUrl(upsell?.upsellProduct.images[0]?.url);
    } else {
      return "https://dummyimage.com/1200&text=Product+Image";
    }
  };

  const [copied, setCopied] = useState(false);

  if (isLoading || !user) return <PageLoader />;

  console.log(user);

  return (
    <main
      className={
        styles.thankYouPage + (!_user ? " " + styles.thankYouPageNoUser : "")
      }
    >
      {/* ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— */}
      <CreatorNavBar creator={user} />
      {/*  ADD SHARE BUTTONS  */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        {Array.from({ length: confettiCount }, (_, index) => (
          <ConfettiComponent key={index} />
        ))}
      </div>

      {/* <div className={styles.mainHeader}>
        <CheckCircle size={40} color="#11d418" />
        <h1>Purchase Successful</h1>
        <h2>For your purchase</h2>
        <div className={styles.downloadButton} onClick={downloadProduct}>
          {product.isFile ? "Download product" : "Access product"}
        </div>
        <br />
      </div> */}
      <div className={styles.bodyContent}>
        <div className={styles.topAreaContainer}>
          <div className={styles.topArea}>
            <div className={styles.productContainer}>
              <div className={styles.imageContainer}>
                <img
                  className={styles.image}
                  src={getThumbnail()}
                  alt={product?.name}
                />
              </div>
              <h3>{product?.name}</h3>
              <div className={styles.userAmount}>
                <div className={styles.user}>
                  <img
                    className={styles.userImage}
                    src={user?.picture}
                    alt={user?.name}
                  />
                  <div className={styles.name}>
                    {user?.firstName || user?.name} {user?.lastName}
                  </div>
                </div>
                {/* <div className={styles.amount}>
                  {formatCurrency(
                    price ? price.amount : product.price,
                    currency
                  )}
                </div> */}
              </div>
              <div className={styles.prodButton}>
                <Button text="Get product" onClick={downloadProduct} />
              </div>
            </div>
            {upsell && (
              <div className={styles.productContainer}>
                <div className={styles.alsoBought}>You also bought</div>
                <div className={styles.imageContainer}>
                  <img
                    className={styles.image}
                    src={getUpsellThumbnail()}
                    alt={upsell.upsellProduct.name}
                  />
                </div>
                <h3>{upsell.upsellProduct.name}</h3>
                <div className={styles.userAmount}>
                  <div className={styles.user}>
                    <img
                      className={styles.userImage}
                      src={user?.picture}
                      alt={user.name}
                    />
                    <div className={styles.name}>
                      {user.firstName || user.name} {user.lastName}
                    </div>
                  </div>

                  {/* <div className={styles.amount}>
                    {formatCurrency(
                      price && price.upsellAmount
                        ? price.upsellAmount
                        : upsell.upsell.upsellPrice,
                      currency
                    )}
                  </div> */}
                </div>
                <div className={styles.prodButton}>
                  <Button
                    text="Get offer product"
                    onClick={downloadUpsellProduct}
                  />
                </div>
                {/* <br /> */}
                {/* <Button text="Get product" onClick={downloadProduct} /> */}
              </div>
            )}
            {!isSubscription && (
              <div className={styles.reviewContainer}>
                <FormGroup label="Leave a review">
                  <Rating
                    onClick={handleRating}
                    initialValue={review?.rating || 0}
                    transition
                    allowFraction
                  />
                </FormGroup>
                <div className={styles.reviewTextContainer}>
                  <FormGroup label="Review">
                    <TextArea
                      name="review"
                      value={reviewText}
                      onChange={(e) => setReviewText(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <Button
                  text="Submit"
                  disabled={
                    review?.rating === rating && review?.review === reviewText
                  }
                  onClick={() => {
                    toast.promise(
                      Axios.post("/reviews", {
                        rating,
                        review: reviewText,
                        saleId: sale?._id,
                        productId: product?._id,
                        buyerEmail: sale.buyerEmail,
                      }).then(() => {
                        setReview({ rating, review: reviewText });
                      }),
                      {
                        loading: "Submitting review...",
                        success: "Review submitted!",
                        error: "Error submitting review",
                      }
                    );
                  }}
                />
              </div>
            )}
            <div className={styles.share}>
              <div className={styles.label}>Share your purchase 🎉</div>
              <SocialSharing
                url={`https://${user?.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product?._id}`}
                text={`I just bought "${product?.name}" and I'm loving it! Check it out here:`}
              />
            </div>
          </div>
          <div
            className={
              styles.creatorMessageContainer + " creatorMessageContainer"
            }
            ref={divRef}
          >
            <div className={styles.creatorMessage}>
              <div className={styles.checkContainer}>
                <Check className={styles.icon} size={44} />
              </div>
              <h1 className={styles.title}>
                {isSubscription
                  ? "Thank you for your payment"
                  : "Thank you for your purchase"}
              </h1>
              <p className={styles.text}>
                {isSubscription ? (
                  <>
                    You started this subscription <b>{product?.name}</b> from{" "}
                    {user?.firstName + " " + user?.lastName}, to get this
                    product you can either click the button or copy and paste
                    the link below.
                  </>
                ) : (
                  <>
                    You purchased <b>{product?.name}</b> from{" "}
                    {user?.firstName + " " + user?.lastName}, to get this
                    product you can either click the button or copy and paste
                    the link below.
                  </>
                )}
              </p>
              <div className={styles.buttonsContainer}>
                <Button text="Get product" onClick={downloadProduct} />
                <Button
                  type="button"
                  variant="secondary"
                  text={copied ? "Copied!" : "Copy link"}
                  onClick={() => {
                    // copy current url to clipboard

                    // timeout to show "copied" text
                    navigator.clipboard.writeText(
                      `https://${user?.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout/success?saleId=${sale?._id}&redirect_status=succeeded`
                    );
                    toast.success("Copied");

                    setCopied(true);

                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <h1>Thank you for purchasing</h1>
      <h3>{product.name}</h3>
      <p>{product.price}</p> */}
      </div>
      {showSignupPopup && (
        <div className={styles.signupPopup}>
          <div className={styles.closeButton} onClick={onClose}>
            <X size={18} />
          </div>
          <h3>Sign in to view all your purchases</h3>
          <p>
            Sign in to keep and manage all of your purchased products in one
            place.
          </p>
          <div className={styles.buttons}>
            <a href="https://app.pocketsflow.com" target="_blank">
              <Button text="Login" variant="secondary" />
            </a>
            <a href="https://app.pocketsflow.com" target="_blank">
              <Button text="Signup" />
            </a>
          </div>
        </div>
      )}
    </main>
  );
};

export default CheckoutSuccess;
