import React from "react";
import { Modal } from "src/components";
import TogglableTabs from "../Product2/steps/DefineProduct/ToggleTabs";
import { useNavigate } from "react-router-dom";

interface ProductTypeSelectorProps {
  onClose: () => void;
}

const ProductTypeSelector: React.FC<ProductTypeSelectorProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>("one-time");

  const items = [
    {
      id: "one-time",
      title: "One-time Product",
      subTitle: "Sell digital products, courses, or any digital content with a one-time payment.",
    },
    {
      id: "subscription",
      title: "Subscription",
      subTitle: "Create recurring revenue with subscription-based products and services.",
      isNew: true,
    },
  ];

  return (
    <Modal
      title="What would you like to create?"
      onClose={onClose}
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        label: "Continue",
        onClick: () => {
          if (selected === "one-time") {
            navigate("/products/new");
          } else {
            navigate("/subscriptions/new");
          }
          onClose();
        },
      }}
    >
      <div style={{ width: "600px", maxWidth: "100%" }}>
        <TogglableTabs
          items={items}
          value={selected}
          onChange={(value) => setSelected(value)}
          isInSelector={true}
        />
      </div>
    </Modal>
  );
};

export default ProductTypeSelector; 