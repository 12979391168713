import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import lodash from "lodash";
import styles from "./Product.module.scss";
import styles2 from "./steps/ProductPage/ProductPage.module.scss";
import { ProductType } from "./Product.types";
import { defaultProduct, STEPS } from "./Product.consts";
import { Button, Modal, SwitchInput } from "src/components";
import { FiArrowLeft } from "react-icons/fi";
import {
  CheckoutPage,
  ProductPage,
  SavingStep,
  DefineProduct,
  ProductEmail,
} from "./steps";
import { ProductSale } from "../Sales/ProductSale";
import { Terminal, X } from "react-feather";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Checkout } from "../Sales/Checkout";
import DataLosePrompt from "src/components/DataLosePrompt";
import { PageLoader } from "src/components/page-loader";
import { getFile } from "./Product.utils";
import Axios from "src/services/api";
import toast from "react-hot-toast";
import usePopups from "src/services/usePopups";
import { ShareModal } from "./components";
import { makeCdnUrl } from "src/services/cdn";
import { IoAlertCircle } from "react-icons/io5";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Product2 = () => {
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const createdParam = searchParams.get("created");
  const showShareModalParam = searchParams.get("showShareModal");
  const showBankAlertModalParam = searchParams.get("showBankAlertModal");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [bigPreview, setBigPreview] = useState<any>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [mutating, setMutating] = useState<boolean>(false);
  const [product, setProduct] = React.useState<ProductType>(defaultProduct);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [variants, setVariants] = useState<any[]>([]);

  const [previousProduct, setPreviousProduct] =
    useState<ProductType>(defaultProduct);
  const [savedFiles, setSavedFiles] = useState<{
    file: File | null;
    thumbnail: File | null;
    images: File[] | null;
  }>({
    file: null,
    thumbnail: null,
    images: null,
  });

  const { popups, togglePopup } = usePopups([
    "deleteProduct",
    "bankAlert",
    "share",
    "copy",
  ]);

  const [isYesCheckout, setIsYesCheckout] = useState<boolean>(false);
  const [copiedId, setCopiedId] = useState<boolean>(false);

  const clickNext = (saveDirectly = false) => {
    if (currentStep < STEPS.length && !saveDirectly) {
      setCurrentStep(currentStep + 1);
    } else {
      if (currentStep < STEPS.length) {
        setCurrentStep(currentStep + 1);
      }
      mutateProduct(saveDirectly);
    }
  };

  const [file, setFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [images, setImages] = useState<File[] | null>(null);
  const [errors, setErrors] = useState<any>({});

  const productDescription = useMemo(() => {
    return product.description
      ? product.description
      : `<div>
      <h2>Introducing Product</h2>
      <p>
          <em>The ultimate product for the modern era.</em>
      </p>
      <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel sapien nulla. Morbi vitae enim nec risus tristique varius. Vestibulum eu aliquet arcu.
      </p>
      <h3>Unparalleled Product</h3>
      <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt dictum dolor, a placerat tortor tristique ac. Quisque in libero lacinia, vestibulum nisi a, iaculis justo. Nulla facilisi.
      </p>
      <h3>Seamless Product</h3>
      <p>
          Mauris suscipit vestibulum sagittis. Vivamus venenatis, risus vel congue commodo, nisl odio bibendum mi, nec laoreet nibh arcu non risus. Nunc egestas ipsum et ligula vehicula, quis fermentum ante convallis.
      </p>
      <h3>AI-Powered Product</h3>
      <p>
          In gravida erat sit amet sapien iaculis, eget ornare ante viverra. Fusce a aliquam dui, et cursus libero. Aliquam nec neque sed nulla volutpat tristique. Integer quis eros vitae nunc placerat elementum.
      </p>
  </div>`;
  }, [product.description]);

  useEffect(() => {
    if (currentStep === 3) {
      setIsYesCheckout(true);
    }

    if (currentStep !== 3) {
      setIsYesCheckout(false);
    }
  }, [currentStep]);

  const productThumbnail = useMemo(() => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }
    return "https://dummyimage.com/1200&text=Product+Thumbnail";
  }, [thumbnail]);

  const productCheckoutText = useMemo(() => {
    return product.checkoutText;
  }, [product.checkoutText]);

  const isEqual =
    lodash.isEqual(product, previousProduct) &&
    lodash.isEqual(savedFiles, {
      file,
      thumbnail,
      images,
    });

  // const [isFormDirty, setIsFormDirty] = useState(false);

  // useEffect(() => {
  //   setIsFormDirty(!isEqual);
  // }, [isEqual]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get(`/products/${id}`).then(async (res) => {
          setProduct({
            ...res.data,
            thumbnail:
              res.data.thumbnail === "" || res.data.thumbnail === "null"
                ? undefined
                : res.data.thumbnail,
          });

          setPreviousProduct({
            ...res.data,
            thumbnail:
              res.data.thumbnail === "" || res.data.thumbnail === "null"
                ? undefined
                : res.data.thumbnail,
          });

          if (
            res.data.thumbnail &&
            res.data.thumbnail !== "" &&
            res.data.thumbnail !== "null"
          ) {
            getFile(res.data.thumbnail).then((file) => {
              setThumbnail(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                thumbnail: file,
              }));
            });
          }

          if (res.data.images && res.data.images.length > 0) {
            await Axios.get(`/images/product/${id}`).then(async (res) => {
              let prodimgs: any = [];
              const images = res.data;
              await images.forEach(async (image: any, index: number) => {
                if (!image) return;
                if (!image?.url) return;

                await getFile(image.url).then((file) => {
                  if (!file) return;
                  prodimgs[index] = file;
                  setImages(prodimgs);
                  setSavedFiles((prevSavedFiles) => {
                    return {
                      ...prevSavedFiles,
                      images: prodimgs,
                    };
                  });
                });
              });
            });
          }

          if (
            res.data.file &&
            res.data.file !== "" &&
            res.data.file !== "null"
          ) {
            getFile(res.data.file).then((file) => {
              setFile(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                file,
              }));
            });
          }
          setLoading(false);
        }),
        {
          loading: "Loading product...",
          success: <>Product loaded</>,
          error: <>Could not load product</>,
        }
      );
    }
  }, [id]);

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      console.log(res.data);
      setStripeAccount(res.data);
    });
  }, []);

  // const isStripeConnected =
  //   stripeAccount && stripeAccount?.message !== "No stripe account connected";
  const hasBankAccount = stripeAccount?.external_accounts?.data.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  const validateForm = (saveDirectly?: boolean) => {
    let formErrors: any = {};
    // if (currentStep === 1 || saveDirectly) {
    if (!product.name) formErrors.name = "Name is required";
    // if (!product.subtitle) formErrors.subtitle = "Subtitle is required";

    // if product is file, check if file is uploaded
    if (product.isFile) {
      if (!file) {
        formErrors.file = "Product File is required";
        setCurrentStep(1);
      }
    } else {
      if (!product.url) {
        formErrors.url = "Product URL is required";
        setCurrentStep(1);
      }
    }

    if (!product.payWant) {
      if (!product.price && product.price !== 0) {
        setCurrentStep(1);
        formErrors.price = "Price is required";
      } else if (isNaN(Number(product.price))) {
        setCurrentStep(1);
        formErrors.price = "Price must be a number";
      } else if (Number(product.price) < 0) {
        setCurrentStep(1);
        formErrors.price = "Price must be greater than 0";
      }
    } else {
      if (!product.minPrice && product.minPrice !== 0) {
        setCurrentStep(1);
        formErrors.minPrice = "Min price is required";
      } else if (isNaN(Number(product.minPrice))) {
        setCurrentStep(1);
        formErrors.minPrice = "Min price must be a number";
      } else if (Number(product.minPrice) < 0) {
        setCurrentStep(1);
        formErrors.minPrice = "Min price must be greater than 0";
      }

      if (!product.maxPrice && product.maxPrice !== 0) {
        setCurrentStep(1);
        formErrors.maxPrice = "Max price is required";
      } else if (isNaN(Number(product.maxPrice))) {
        setCurrentStep(1);
        formErrors.maxPrice = "Max price must be a number";
      } else if (Number(product.maxPrice) < 0) {
        setCurrentStep(1);
        formErrors.maxPrice = "Max price must be greater than 0";
      }
    }

    if (product.emailCallbackUrl && product.emailCallbackUrl.length > 0) {
      if (!isValidURL(product.emailCallbackUrl)) {
        setCurrentStep(4);
        formErrors.emailCallbackUrl =
          "Email Button URL needs to be a valid URL";
      }
    }
    // }

    setErrors(formErrors);

    // show an error toast if there are errors
    if (Object.keys(formErrors).length > 0) {
      toast.error(
        <>
          <div className={styles.errorToast}>
            <div className={styles.errorToastTitle}>
              Please fix the following errors:
            </div>
            <ul>
              {Object.keys(formErrors).map((key: string) => (
                <li key={key}>{formErrors[key]}</li>
              ))}
            </ul>
          </div>
        </>,
        {
          duration: 40000,
        }
      );
    }

    return Object.keys(formErrors).length === 0;
  };

  const mutateProduct = async (saveDirectly?: boolean) => {
    if (!validateForm(saveDirectly)) return;

    setMutating(true);
    const formData = new FormData();

    // compareAndAppend(formData, "file", file, savedProduct?.file);

    // only append file, thumbnail, and images if they are not the same as the savedFiles

    if (file !== savedFiles?.file) formData.append("file", file!);

    if (thumbnail !== savedFiles?.thumbnail)
      formData.append("thumbnail", thumbnail!);

    if (images) {
      for (let i = 0; i < images!.length; i++) {
        formData.append("images", images[i] as File);
      }
    }

    let showWarning = false;

    let published = product.published;

    if (!hasBankAccount && !hasPayPal) {
      published = false;
      showWarning = true;
      if (product.price === 0 && !product.payWant) {
        showWarning = false;
        published = true;
      }
    }

    formData.append("url", product.url);
    formData.append("refundPolicy", product.refundPolicy || "");
    formData.append("isFile", product.isFile as any);
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("subtitle", product.subtitle);
    formData.append("price", (product.price || 0).toString());
    formData.append("published", published?.toString());
    formData.append("showSales", product.showSales?.toString());
    formData.append("showReviews", product.showReviews?.toString());
    formData.append("design", product.design?.toString());
    formData.append("colors", JSON.stringify(product.colors));
    formData.append("ctaText", product.ctaText);
    formData.append("checkoutText", product.checkoutText || "");
    formData.append("thankYouText", product.thankYouText || "");
    formData.append("payWant", product.payWant?.toString());
    formData.append("minPrice", (product.minPrice || 0)?.toString());
    formData.append("maxPrice", (product.maxPrice || 0)?.toString());
    formData.append("hasProductPage", product.hasProductPage || "no");
    formData.append("emailCallbackUrl", product.emailCallbackUrl || "");

    if (id) {
      if (false) {
        toast.error(
          "Product not published, please connect your stripe account and add a bank account"
        );
        setMutating(false);
        return;
      } else {
        toast.promise(
          Axios.post(`/products/update/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              setMutating(false);
              setPreviousProduct(product);

              if (showWarning) {
                togglePopup("bankAlert");
              } else {
                togglePopup("share");
              }

              setSavedFiles({
                file,
                thumbnail,
                images,
              });

              // navigate(`/products/${id}`);
              setIsCreated(false);
              setCurrentStep(5);
              setMutating(false);
            })
            .catch((err) => {
              console.log(err);
              setMutating(false);
            }),
          {
            loading: "Saving product...",
            success: <>Product saved</>,
            error: <>Could not save product</>,
          }
        );
      }
    } else {
      // @ts-ignore
      // window.gtag("event", "Create_Product", {
      //   userId: me._id,
      // });

      const isThereVariants = variants?.length > 0;

      toast.promise(
        Axios.post("/products", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            setProduct(res.data);
            setPreviousProduct(res.data);
            if (showWarning) {
              togglePopup("bankAlert");
            }
            setSavedFiles({
              file,
              thumbnail,
              images,
            });

            if (isThereVariants) {
              // update variants with productId
              variants?.forEach((variant) => {
                Axios.post(`/products/variants/update-product/${variant._id}`, {
                  productId: res.data._id,
                });
              });
            }

            setIsCreated(true);
            setMutating(false);
            setCurrentStep(5);
          })
          .catch((err) => {
            console.log(err);
            setMutating(false);
          }),
        {
          loading: "Saving product...",
          success: <>Product saved</>,
          error: <>Could not save product</>,
        }
      );
    }
  };

  useEffect(() => {
    if (copiedId) {
      setTimeout(() => {
        setCopiedId(false);
      }, 2000);
    }
  }, [copiedId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <DataLosePrompt isDirty={!isEqual && currentStep !== 4} />
      <div className={styles.productPage}>
        <div className={styles.navbar}>
          <div className={styles.titleContainer}>
            <div
              className={styles.backButton}
              onClick={() => navigate("/products")}
            >
              <FiArrowLeft size={20} color="#3447ef" />
            </div>
            <div className={styles.productTitle}>
              {id && (
                <img
                  src={
                    product.thumbnail
                      ? makeCdnUrl(product.thumbnail)
                      : "https://dummyimage.com/1200&text=Product+Image"
                  }
                />
              )}
              <h1>{id ? product.name || "Save Product" : "Create product"}</h1>
            </div>
            {!isEqual && (
              <div
                title="You have unsaved changes"
                className={styles.isEqualRedDot}
              ></div>
            )}
          </div>
          <div className={styles.actions}>
            {id && product.published && (
              <>
                <Button
                  type="button"
                  variant="tertiary"
                  text="Share Product"
                  onClick={() => togglePopup("share")}
                />
                <div className={styles.divider} />
              </>
            )}
            {((currentStep === 2 && product.hasProductPage === "yes") ||
              isYesCheckout) && (
              <>
                <Button
                  text={
                    <div className={styles2.buttonIcon}>
                      <MdOutlineRemoveRedEye size={18} />
                      {currentStep === 2 && product.hasProductPage === "yes"
                        ? "Preview product page"
                        : isYesCheckout
                        ? "Preview checkout page"
                        : ""}
                    </div>
                  }
                  onClick={() => {
                    if (currentStep === 2 && product.hasProductPage === "yes") {
                      setBigPreview("product");
                    }
                    if (isYesCheckout) {
                      setBigPreview("checkout");
                    }
                  }}
                />
                <div className={styles.divider} />
              </>
            )}
            <div
              className={
                styles.publishedItem +
                (product.published ? " " + styles.published : "")
              }
            >
              <SwitchInput
                checked={product.published}
                onChange={(checked: any) => {
                  setProduct({ ...product, published: checked });
                }}
                id="published"
                name="published"
              />
              <span>{product.published ? "Published" : "Unpublished"}</span>
            </div>
            <div className={styles.divider} />
            {currentStep === 2 || currentStep === 3 ? (
              <Button
                variant="secondary"
                text="Back"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              />
            ) : (
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  navigate("/products");
                }}
              />
            )}
            <Button
              text={
                currentStep === 1 || currentStep === 2
                  ? "Next"
                  : id
                  ? "Save product"
                  : "Create product"
              }
              disabled={mutating || (currentStep === 3 && isEqual)}
              onClick={() => clickNext()}
            />
            {/* Cancel */}
            {/* Save */}
            {/* More actions */}
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.steps}>
            {id && (
              <div className={styles.idContainerBig}>
                <div
                  className={styles.idContainer}
                  data-tooltip-id="product-id-tooltip"
                  data-tooltip-content={copiedId ? "Copied!" : "Click to copy"}
                  onClick={() => {
                    navigator.clipboard.writeText(id || "");
                    setCopiedId(true);
                  }}
                >
                  <div className={styles.idIcon}>
                    <Terminal size={9} />
                  </div>
                  <div className={styles.idText}>{product._id}</div>
                </div>
                {copiedId && <div className={styles.copiedId}>Copied!</div>}
              </div>
            )}
            <div className={styles.stepsContainer}>
              {STEPS.map((step, index) => (
                <div
                  key={index}
                  className={`${styles.stepItem} ${
                    currentStep === index + 1 ? styles.stepItemActive : ""
                  }`}
                  onClick={() => setCurrentStep(index + 1)}
                >
                  <div className={styles.iconContainer}>{step.icon}</div>
                  <div className={styles.textContainer}>
                    <h5>{step.name}</h5>
                    <p>{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.sideButtonContainer}>
              {!isEqual && (
                <Button
                  variant="primary"
                  text={id ? "Save product" : "Create product"}
                  onClick={() => clickNext(true)}
                />
              )}
              {id && product.published && (
                <Button
                  variant="secondary"
                  text="Share product"
                  onClick={() => {
                    togglePopup("share");
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.form}>
            {/* <div className={styles.savePopup}>
              <div className={styles.savePopupText}>
                {id ? product.name || "Save Product" : "Create product"}
              </div>
              {currentStep === 2 || currentStep === 3 || currentStep === 4 ? (
                <Button
                  variant="secondary"
                  text="Back"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                />
              ) : (
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    navigate("/products");
                  }}
                />
              )}
              <Button
                text={
                  currentStep === 1 || currentStep === 2 || currentStep === 3
                    ? "Next"
                    : "Save"
                }
                disabled={mutating || (currentStep === 4 && isEqual)}
                onClick={() => clickNext()}
              />
            </div> */}
            <div className={styles.insideForm}>
              {currentStep === 1 ? (
                <DefineProduct
                  product={product}
                  setProduct={setProduct}
                  file={file}
                  setFile={setFile}
                  thumbnail={thumbnail}
                  setThumbnail={setThumbnail}
                  images={images}
                  setImages={setImages}
                  errors={errors}
                  variants={variants}
                  setVariants={setVariants}
                />
              ) : currentStep === 2 ? (
                <ProductPage
                  product={product}
                  setProduct={setProduct}
                  images={images}
                  setImages={setImages}
                  errors={errors}
                  onNextPage={() => {
                    setCurrentStep(currentStep + 1);
                  }}
                  setBigPreview={setBigPreview}
                  bigPreview={bigPreview}
                />
              ) : currentStep === 3 ? (
                <CheckoutPage
                  product={product}
                  setProduct={setProduct}
                  errors={errors}
                />
              ) : currentStep === 4 ? (
                <ProductEmail
                  product={product}
                  setProduct={setProduct}
                  errors={errors}
                />
              ) : currentStep === 5 ? (
                <SavingStep
                  product={product}
                  isCreated={isCreated}
                  hasBankAccount={hasBankAccount}
                  hasPayPal={hasPayPal}
                />
              ) : null}
            </div>
          </div>
        </div>
        {bigPreview && (
          <div
            className={styles.bigModalPreviewContainer}
            onClick={() => setBigPreview(undefined)}
          >
            <div
              className={styles.closeContainer}
              onClick={() => setBigPreview(undefined)}
            >
              <X className={styles.modalCloseButton} />
            </div>
            <div
              className={styles.pagesBigPreview}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                {bigPreview === "product" ? (
                  <ProductSale
                    demoMode
                    prod={{
                      ...product,
                      name: product.name || "Example product name",
                      images:
                        images && images?.length
                          ? images?.map((image: any) => {
                              console.log(image);
                              return {
                                url: URL.createObjectURL(image),
                              };
                            })
                          : [
                              {
                                url: "https://dummyimage.com/1200&text=Product+Image",
                              },
                            ],
                      description: productDescription,
                    }}
                    vars={variants}
                  />
                ) : bigPreview === "checkout" ? (
                  <Checkout
                    demoMode
                    _user={{
                      ...me,
                    }}
                    id={id}
                    isProdPage
                    prod={{
                      ...product,
                      thumbnail: productThumbnail,
                      checkoutText: productCheckoutText,
                    }}
                    vars={variants}
                  />
                ) : null}
              </Suspense>
            </div>
          </div>
        )}
      </div>
      {popups.deleteProduct && (
        <Modal
          onClose={() => togglePopup("deleteProduct")}
          title="Delete Product"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteProduct");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/products/" + id)
                .then(() => {
                  togglePopup("deleteProduct");
                  toast.success("Product deleted!");
                  navigate("/products");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteProduct");
                  toast.error("Failed to delete product");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this product?
          </div>
        </Modal>
      )}
      {(showBankAlertModalParam === "true" || popups.bankAlert) && (
        <Modal
          onClose={() =>
            navigate("/products/" + product._id, { replace: true })
          }
          title="Connect a Payment Method"
          footerRightButton1={{
            // @ts-ignore
            label: "Cancel",
            onClick: () => {
              togglePopup("bankAlert");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Connect Bank Account or PayPal",
            onClick: () => {
              navigate("/profile/payments");
            },
          }}
        >
          <div className={styles.bankModalText}>
            <IoAlertCircle fontSize={24} className={styles.bankModalIcon} />
            Your product isn't free, so to start making sales you need to
            connect either your bank account or PayPal so you can get paid out.
          </div>
        </Modal>
      )}
      {(showShareModalParam === "true" || popups.share) && (
        <ShareModal created={createdParam} product={product} />
      )}

      <ReactTooltip place="top" variant="dark" float id="product-id-tooltip" />
    </>
  );
};

function isValidURL(url: string) {
  const regex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
  return regex.test(url);
}

export default Product2;
