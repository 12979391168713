import React from "react";
import styles from "./Product.module.scss";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

type Item = {
  id: string;
  title: string;
  subTitle: string;
  soon?: boolean;
  isNew?: boolean;
};

const TogglableTabs = ({
  items,
  onChange,
  value,
  isInSelector,
}: {
  items: Item[];
  onChange: (value: string) => void;
  value: string;
  isInSelector?: boolean;
}) => {
  return (
    <div className={`${styles.togglableTabs} ${isInSelector ? styles.inSelector : ""}`}>
      {items.map((item) => (
        <div
          key={item.id}
          className={`${styles.tab} ${
            value === item.id ? styles.selected : ""
          } ${
            item.soon ? styles.soon : ""
          } ${isInSelector ? styles.inSelector : ""}`}
          onClick={() => onChange(item.id)}
        >
          {value === item.id && (
            <div className={styles.check}>
              <IoCheckmarkCircleSharp size={24} />
            </div>
          )}
          {item.soon ? (
            <h3 className={styles.soonText}>Soon</h3>
          ) : null}
          <div className={styles.titleContainer}>
            <h3 className={`${styles.togglableTabTitle} ${isInSelector ? styles.selectorTitle : ""}`}>
              {item.title}
            </h3>
            {item.isNew && isInSelector ? (
              <span className={styles.newBadge}>New</span>
            ) : null}
          </div>
          <p className={`${styles.togglableTabP} ${isInSelector ? styles.selectorDescription : ""}`}>
            {item.subTitle}
          </p>
        </div>
      ))}
    </div>
  );
};

export default TogglableTabs;
