import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SavingStepSubscription = ({ subscription, isCreated }: any) => {
  console.log(subscription);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isCreated) {
      navigate(
        `/subscriptions/${subscription?._id}?created=true&showShareModal=true`,
        {
          replace: true,
        }
      );
    } else {
      navigate(
        `/subscriptions/${subscription?._id}?created=false&showShareModal=true`,
        {
          replace: true,
        }
      );
    }
  }, []);

  return <></>;
};

export default SavingStepSubscription;
