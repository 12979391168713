import React, { useEffect } from "react";
import styles from "./Products.module.scss";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import { Product } from "src/models/product";
import { Button, EmptyState, Modal, TextInput } from "src/components";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import { formatCurrency } from "src/services/currency";
import usePopups from "src/services/usePopups";
import ImportModal from "./ImportModal";
import ImportlmsModal from "./ImportlmsModal";
import { makeCdnUrl } from "src/services/cdn";
import { IoAlertCircle } from "react-icons/io5";
import { PageLoader } from "src/components/page-loader";
import { Subscription } from "../Subscriptions/Subscription.types";
import ProductTypeSelector from "./ProductTypeSelector";

const columns = [
  "Name",
  "Type",
  "Price",
  "Created at",
  "View product",
  "Published",
];

const Products: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
  const [id, setId] = React.useState<string>("");
  const { popups, togglePopup } = usePopups([
    "import",
    "bankAlert",
    "importlms",
    "deleteProduct",
    "productType",
  ]);
  const [reload, setReload] = React.useState<boolean>(false);
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  // NEW: Define a union type for combined items
  type CombinedItem =
    | (Product & { type: "One-time" })
    | (Subscription & { type: "Subscription" });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      Axios.get<Product[]>("/products"),
      Axios.get<Subscription[]>("/subscriptions"),
    ])
      .then(([productsRes, subscriptionsRes]) => {
        setProducts(productsRes.data);
        setSubscriptions(subscriptionsRes.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error loading data");
      });
  }, [reload]);

  // Then update the combinedList memo to explicitly type the mapped arrays:
  const combinedList = React.useMemo(() => {
    const prodWithType: CombinedItem[] = products.map((p) => ({
      ...p,
      type: "One-time",
    }));
    const subsWithType: CombinedItem[] = subscriptions.map((s) => ({
      ...s,
      type: "Subscription",
    }));
    return [...prodWithType, ...subsWithType].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [products, subscriptions]);

  const filteredItems = React.useMemo(() => {
    if (search === "") return combinedList;
    return combinedList.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, combinedList]);

  if (loading || (!products && !subscriptions)) return <PageLoader />;

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <Button
              text="Create New"
              onClick={() => togglePopup("productType")}
            />
            <div className={styles.newDot}>
              <div className={styles.newDotInner} />
            </div>
          </div>
        }
      >
        Products
      </PageTitle>

      <div className={styles.learnMore}>
        <div className={styles.learnMoreText}>
          Subscriptions are a new feature, learn more about how to use them
          here:{" "}
          <Link
            to="https://help.pocketsflow.com/en/articles/10516902-subscriptions"
            target="_blank"
          >
            Subscriptions docs
          </Link>
        </div>
      </div>

      {filteredItems?.length === 0 && search === "" ? (
        <EmptyState
          loading={loading}
          title="Create your first product or subscription"
          subTitle="Create your first product or subscription and start selling it to your customers."
          buttonText="Create product or subscription"
          buttonClick={() => {
            navigate("/products/new");
          }}
        />
      ) : (
        <>
          <div className={styles.seachInput}>
            <TextInput
              name="search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              placeholder="Search Products or Subscriptions..."
            />
          </div>
          {filteredItems?.length === 0 && search !== "" ? (
            <EmptyState
              loading={loading}
              title="No products or subscriptions found"
              subTitle="No products or subscriptions found with this query, change your search query to find items."
            />
          ) : (
            <div className={styles.tableContainer}>
              <table className={styles.productsTable}>
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredItems?.map((item, index) => {
                    return (
                      <tr key={item._id || index}>
                        <td
                          style={{ width: "30%" }}
                          onClick={() =>
                            navigate(
                              item.type === "Subscription"
                                ? `/subscriptions/${item._id}`
                                : `/products/${item._id}`
                            )
                          }
                        >
                          <div className={styles.productNameContainer}>
                            <img
                              src={
                                item.thumbnail
                                  ? makeCdnUrl(item.thumbnail)
                                  : "https://dummyimage.com/1200&text=Product+Image"
                              }
                              alt="thumbnail"
                            />
                            <div>{item.name}</div>
                          </div>
                        </td>
                        <td style={{ width: "15%" }}>
                          <span
                            className={`${styles.badge} ${
                              item.type === "One-time"
                                ? styles.badgeOneTime
                                : styles.badgeSubscription
                            }`}
                          >
                            {item.type}
                          </span>
                        </td>
                        <td style={{ width: "12%" }}>
                          <div style={{ whiteSpace: "nowrap" }}>
                            {formatCurrency(item.price)}
                            {item.type === "Subscription" &&
                            (item as any).frequency
                              ? " / " + (item as any).frequency
                              : ""}
                          </div>
                        </td>
                        <td style={{ width: "13%" }}>
                          {dayjs(item.createdAt).fromNow()}
                        </td>
                        <td style={{ width: "10%" }}>
                          <a
                            onClick={() =>
                              navigate(
                                item.type === "Subscription"
                                  ? `/subscriptions/${item._id}`
                                  : `/products/${item._id}`
                              )
                            }
                            target="_blank"
                          >
                            <Button variant="tertiary" text="View" />
                          </a>
                        </td>
                        <td style={{ width: "12%", textAlign: "right" }}>
                          {item.published ? "Published" : "Unpublished"}
                        </td>
                        {/* <td style={{ width: "8%" }}> */}
                        {/* Additional actions can go here */}
                        {/* </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      {popups.import && (
        <ImportModal
          onClose={() => {
            setReload(!reload);
            togglePopup("import");
          }}
        />
      )}
      {popups.importlms && (
        <ImportlmsModal
          onClose={() => {
            setReload(!reload);
            togglePopup("importlms");
          }}
        />
      )}
      {popups.deleteProduct && (
        <Modal
          onClose={() => togglePopup("deleteProduct")}
          title="Delete Product"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteProduct");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/products/" + id)
                .then(() => {
                  togglePopup("deleteProduct");
                  toast.success("Product deleted!");
                  navigate("/products");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteProduct");
                  toast.error("Failed to delete product");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this product?
          </div>
        </Modal>
      )}
      {popups.bankAlert && (
        <Modal
          onClose={() => togglePopup("bankAlert")}
          title="Connect a Payment Method"
          footerRightButton1={{
            // @ts-ignore
            label: "Cancel",
            onClick: () => {
              togglePopup("bankAlert");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Connect Bank Account or PayPal",
            onClick: () => {
              navigate("/profile/payments");
            },
          }}
        >
          <div className={styles.bankModalText}>
            <IoAlertCircle fontSize={24} className={styles.bankModalIcon} />
            Your product isn't free, so to start making sales you need to
            connect either your bank account or PayPal so you can get paid out.
          </div>
        </Modal>
      )}
      {popups.productType && (
        <ProductTypeSelector onClose={() => togglePopup("productType")} />
      )}
    </div>
  );
};

export default Products;
