import React, { useEffect, useState } from "react";
import styles from "./PageLayout.module.scss";
import { Sidebar } from "../Sidebar";
import { Route, Routes, useLocation } from "react-router-dom";
import { Dashboard } from "src/pages/Dashboard";
import { Orders } from "src/pages/Orders";
import { Upsells } from "src/pages/Upsells";
import { Upsell } from "src/pages/Upsell_";
import { Affiliates } from "src/pages/Affiliates";
import { AffiliatedProducts } from "src/pages/AffiliatedProducts";
import { Affiliate } from "src/pages/Affiliate";
import { Products } from "src/pages/Products";
import { Product2 } from "src/pages/Product2";
import { Reviews } from "src/pages/Reviews";
import { Customers } from "src/pages/Customers";
import { Customer } from "src/pages/Customer";
import { Payouts } from "src/pages/Payouts";
import { Profile } from "src/pages/Profile";
import { NotFoundPage } from "src/pages/not-found-page";
import { Guide } from "src/pages/Guide";
import { Order } from "src/pages/Order";
import { Refunds } from "src/pages/Refunds";
import { Refund } from "src/pages/Refund";
import { Help } from "src/pages/Help";
import { PageLoader } from "../page-loader";
import { useAuth0 } from "@auth0/auth0-react";
import Discounts from "src/pages/Discounts";
import { Discount } from "src/pages/Discount";
import { EmptyState } from "../EmptyState";
import { BoughtProducts } from "src/pages/BoughtProducts";
import Developers from "src/pages/Developers/Developers";
import Subscriptions from "src/pages/Subscriptions";
import Subscription from "src/pages/Subscription";
import OrderSubscription from "src/pages/OrderSubscription";

const PageLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const me = localStorage.getItem("me");
    if (me) {
      const meData = JSON.parse(me);
      if (meData.navbarClosed) {
        setSidebarOpen(false);
      }
    }
  }, [location.pathname]);

  // is mobile phone check
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  if (isMobile) {
    return (
      <EmptyState
        title="Mobile is not yet supported"
        buttonText="Go back"
        buttonClick={() => (window.location.href = "https://pocketsflow.com")}
      />
    );
  }

  return (
    <div
      className={styles.pageLayout + " " + (!sidebarOpen ? styles.notOpen : "")}
    >
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <div className={styles.layout}>
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/guide" element={<Guide />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:id" element={<Order />} />
            <Route path="/discounts" element={<Discounts />} />
            <Route path="/discounts/new" element={<Discount />} />
            <Route path="/discounts/:id" element={<Discount />} />
            <Route path="/upsells" element={<Upsells />} />
            <Route path="/upsells/new" element={<Upsell />} />
            <Route path="/upsells/:id" element={<Upsell />} />
            <Route path="/refunds" element={<Refunds />} />
            <Route path="/refunds/new" element={<Refund />} />
            <Route path="/refunds/:id" element={<Refund />} />
            <Route path="/affiliates" element={<Affiliates />} />
            <Route
              path="/affiliated-products"
              element={<AffiliatedProducts />}
            />
            <Route path="/affiliates/new" element={<Affiliate />} />
            <Route path="/affiliates/:id" element={<Affiliate />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/new" element={<Product2 />} />
            <Route path="/products/:id" element={<Product2 />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/subscriptions/new" element={<Subscription />} />
            <Route path="/subscriptions/:id" element={<Subscription />} />
            <Route path="/bought-products" element={<BoughtProducts />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/:id" element={<Customer />} />
            <Route path="/payouts" element={<Payouts />} />
            <Route path="/help" element={<Help />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/developers/*" element={<Developers />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/order-subscription/:id" element={<OrderSubscription />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const Logout = () => {
  const { logout } = useAuth0();
  React.useEffect(() => {
    logout();
  }, [logout]);
  return <PageLoader />;
};

export default PageLayout;
