import React, { useEffect, useRef, useState } from "react";

import styles from "./Orders.module.scss";
import {
  Card,
  EmptyState,
  PageLayout,
  PageTitle,
  TextInput,
} from "src/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Count, Countries } from "../Customers/Customers";
import "../Dashboard/Dashboard.scss";
import { makeCdnUrl } from "src/services/cdn";
import { IoChevronDownOutline } from "react-icons/io5";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Gravatar from "src/components/Gravatar";
import { PageLoader } from "src/components/page-loader";
import { X } from "react-feather";
import { Product } from "src/models/product";

const columns = [
  "ID",
  "Product",
  "Customer",
  "Tax %",
  "Transaction fee",
  "Total paid",
  "Created at",
];

const subscriptionsColumns = [
  "ID",
  "Subscription",
  "Customer",
  "Status",
  "Price",
  "Next invoice",
  "Created at",
];

const Orders = () => {
  const navigate = useNavigate();
  const [state, setState] = useState([
    {
      startDate: new Date(0),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openDate, setOpenDate] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [countries, setCountries] = useState<Countries>({});
  const [firstTime, setFirstTime] = useState(true);

  const [loadingProducts, setLoadingProducts] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [productId, setProductId] = useState<any>(undefined);
  const [openProduct, setOpenProduct] = useState(false);

  const [loadingTab, setLoadingTab] = React.useState<boolean>(true);

  const [activeTab, setActiveTab] = useState("one-time");

  const refProduct = useRef<HTMLDivElement | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (activeTab !== "one-time") {
      return;
    }

    if (!firstTime) return;
    setFirstTime(false);
    setLoadingTab(true);
    Axios.post("/orders", {
      startDate: state[0]?.startDate?.toISOString(),
      endDate: state[0]?.endDate?.toISOString(),
      productId,
    }).then((response) => {
      setOrders(response.data);
      setLoadingTab(false);
      setLoading(false);
    });
  }, [activeTab, productId]);

  useEffect(() => {
    if (activeTab !== "one-time") {
      return;
    }
    const fetchStats = async () => {
      try {
        setLoadingTab(true);
        toast.promise(
          Axios.post("/orders", {
            startDate: state[0]?.startDate?.toISOString(),
            endDate: state[0]?.endDate?.toISOString(),
            productId,
          }).then((response) => {
            setOrders(response.data);
            setLoadingTab(false);
          }),
          {
            loading: "Loading transactions...",
            success: "Transactions loaded",
            error: "Error loading transactions",
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (
      (state[0]?.startDate && state[0]?.endDate) ||
      (!state[0]?.startDate && !state[0]?.endDate && !firstTime)
    ) {
      fetchStats();
    }
  }, [state[0]?.startDate, state[0]?.endDate, firstTime, activeTab, productId]);

  useEffect(() => {
    if (activeTab !== "subscriptions") {
      return;
    }

    if (!firstTime) return;
    setFirstTime(false);
    setLoadingTab(true);
    Axios.post("/orders/subscriptions", {
      startDate: state[0]?.startDate?.toISOString(),
      endDate: state[0]?.endDate?.toISOString(),
      productId,
    }).then((response) => {
      setOrders(response.data.subscriptionCustomers);
      setLoadingTab(false);
      setLoading(false);
    });
  }, [activeTab, productId, state[0]?.startDate, state[0]?.endDate]);

  useEffect(() => {
    if (activeTab !== "subscriptions") {
      return;
    }
    const fetchStats = async () => {
      try {
        setLoadingTab(true);
        toast.promise(
          Axios.post("/orders/subscriptions", {
            startDate: state[0]?.startDate?.toISOString(),
            endDate: state[0]?.endDate?.toISOString(),
            productId,
          }).then((response) => {
            setLoadingTab(false);
            setOrders(response.data.subscriptionCustomers);
          }),
          {
            loading: "Loading transactions...",
            success: "Transactions loaded",
            error: "Error loading transactions",
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (
      (state[0]?.startDate && state[0]?.endDate) ||
      (!state[0]?.startDate && !state[0]?.endDate && !firstTime)
    ) {
      fetchStats();
    }
  }, [state[0]?.startDate, state[0]?.endDate, firstTime, activeTab, productId]);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refProduct.current &&
        !refProduct.current.contains(event.target as Node)
      ) {
        setOpenProduct(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoadingProducts(true);
    Axios.get<Product[]>("/products")
      .then((response) => {
        setProducts(response.data);
        setLoadingProducts(false);
      })
      .catch(() => {
        setLoadingProducts(false);
      });
  }, []);

  useEffect(() => {
    setLoadingSubscriptions(true);
    Axios.get<Product[]>("/subscriptions")
      .then((response) => {
        setSubscriptions(response.data);
        setLoadingSubscriptions(false);
      })
      .catch(() => {
        setLoadingSubscriptions(false);
      });
  }, []);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredSubscriptions = subscriptions.filter((subscription) =>
    subscription.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <div className={styles.productsFilter}>
              <div
                className={styles.productsToggler}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenProduct(!openProduct);
                }}
              >
                <span>
                  {!productId
                    ? activeTab === "one-time"
                      ? "All products"
                      : "All subscriptions"
                    : activeTab === "one-time"
                    ? products.find((product) => product._id === productId)
                        ?.name
                    : subscriptions.find(
                        (subscription) => subscription._id === productId
                      )?.name}{" "}
                </span>
                <div className={styles.iconContainer}>
                  {productId && (
                    <X
                      onClick={(e) => {
                        e.stopPropagation();
                        setProductId(null); // Clear the selected product
                      }}
                      size={18}
                      className={styles.clearIcon}
                    />
                  )}
                  <IoChevronDownOutline
                    style={{
                      marginLeft: 5,
                    }}
                    size={14}
                  />
                </div>
              </div>
              {openProduct && (
                <div className={styles.productsListContainer} ref={refProduct}>
                  <div className={styles.form}>
                    <div className={styles.label}>
                      {activeTab === "one-time"
                        ? "Filter by products"
                        : "Filter by subscriptions"}
                    </div>
                    <TextInput
                      name="search"
                      value={searchTerm}
                      onChange={(e: any) => setSearchTerm(e.target.value)}
                      placeholder={
                        activeTab === "one-time"
                          ? "Search products..."
                          : "Search subscriptions..."
                      }
                      type="text"
                    />
                  </div>
                  {activeTab === "one-time" ? (
                    <div className={styles.productsFilterList}>
                      {loadingProducts ? (
                        <div className={styles.loadingState}>
                          Loading products...
                        </div>
                      ) : products.length === 0 ? (
                        <div className={styles.emptyState}>
                          No products available.
                        </div>
                      ) : filteredProducts.length === 0 ? (
                        <div className={styles.emptySearchState}>
                          No products match your search.
                        </div>
                      ) : (
                        filteredProducts.map((product) => (
                          <div
                            className={styles.productsFilterItem}
                            onClick={() => {
                              setProductId(product._id);
                            }}
                            key={product._id}
                          >
                            <div className={styles.productNameContainer}>
                              <img
                                src={
                                  product.thumbnail
                                    ? makeCdnUrl(product.thumbnail)
                                    : "https://dummyimage.com/1200&text=Product+Image"
                                }
                                alt={product.name}
                              />
                              <div className={styles.productName}>
                                {product.name}
                              </div>
                            </div>
                            <div className={styles.productPrice}>
                              {formatCurrency(product.price)}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  ) : (
                    <div className={styles.productsFilterList}>
                      {loadingSubscriptions ? (
                        <div className={styles.loadingState}>
                          Loading subscriptions...
                        </div>
                      ) : products.length === 0 ? (
                        <div className={styles.emptyState}>
                          No subscriptions available.
                        </div>
                      ) : filteredProducts.length === 0 ? (
                        <div className={styles.emptySearchState}>
                          No subscriptions match your search.
                        </div>
                      ) : (
                        filteredSubscriptions.map((subscription) => (
                          <div
                            className={styles.productsFilterItem}
                            onClick={() => {
                              setProductId(subscription._id);
                            }}
                            key={subscription._id}
                          >
                            <div className={styles.productNameContainer}>
                              <img
                                src={
                                  subscription.thumbnail
                                    ? makeCdnUrl(subscription.thumbnail)
                                    : "https://dummyimage.com/1200&text=Subscription+Image"
                                }
                                alt={subscription.name}
                              />
                              <div className={styles.productName}>
                                {subscription.name}
                              </div>
                            </div>
                            <div className={styles.productPrice}>
                              {formatCurrency(subscription.price)}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className={styles.dateToggler}
              onClick={() => setOpenDate(!openDate)}
            >
              {dayjs(state[0].startDate).format("MMM D, YYYY")} -{" "}
              {dayjs(state[0].endDate).format("MMM D, YYYY")}
              <IoChevronDownOutline
                style={{
                  marginLeft: 5,
                }}
                size={14}
              />
            </div>
            {openDate && (
              <div className={styles.datePicker} ref={ref}>
                <DateRangePicker
                  // @ts-ignore
                  onChange={(item) => setState([item.selection])}
                  // @ts-ignore
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  // @ts-ignore
                  ranges={state}
                  direction="horizontal"
                  inputRanges={[]}
                  staticRanges={[
                    {
                      label: "Today",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Yesterday",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ),
                        endDate: new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ),
                      }),
                    },
                    {
                      label: "This Week",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay()
                          )
                        ),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Week",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay() - 7
                          )
                        ),
                        endDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay() - 1
                          )
                        ),
                      }),
                    },
                    {
                      label: "This Month",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        ),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Month",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() - 1,
                          1
                        ),
                        endDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          0
                        ),
                      }),
                    },
                    {
                      label: "This Year",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(new Date().getFullYear(), 0, 1),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Year",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
                        endDate: new Date(new Date().getFullYear(), 0, 0),
                      }),
                    },
                    {
                      label: "All Time",
                      isSelected: () => true,
                      range: () => ({
                        startDate: new Date(0),
                        endDate: new Date(),
                      }),
                    },
                  ]}
                />
                {/* <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Select a date"
                /> */}
              </div>
            )}
          </div>
        }
      >
        Orders
      </PageTitle>
      <div className={styles.pageTabsContainer}>
        <div
          className={`${styles.tab} ${
            activeTab === "one-time" ? styles.active : ""
          } ${loadingTab ? styles.disableTab : ""}`}
          onClick={() => {
            if (activeTab !== "one-time") {
              setLoadingTab(true);
              setActiveTab("one-time");
              setProductId(undefined);
            } else {
              setLoadingTab(false);
            }
          }}
        >
          One-time products
        </div>
        <div
          className={`${styles.tab} ${
            activeTab === "subscriptions" ? styles.active : ""
          } ${loadingTab ? styles.disableTab : ""}`}
          onClick={() => {
            if (activeTab !== "subscriptions") {
              setLoadingTab(true);
              setProductId(undefined);
              setActiveTab("subscriptions");
            } else {
              setLoadingTab(false);
            }
          }}
        >
          Subscriptions
        </div>
      </div>
      {loading || orders?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Get your first order"
          subTitle={`Create and share your ${
            activeTab === "one-time" ? "products" : "subscriptions"
          } to make sales. Your orders will show up here`}
        />
      ) : (
        <>
          {loadingTab ? (
            <div style={{ paddingTop: "200px" }}>
              <PageLoader />
            </div>
          ) : (
            <>
              <div className={styles.tableContainer}>
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      {(activeTab === "one-time"
                        ? columns
                        : subscriptionsColumns
                      ).map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.map((order, index) => {
                      if (activeTab === "one-time") {
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => {
                                navigate(`/orders/${order?._id!}`);
                              }}
                            >
                              <div className={styles.orderId}>
                                <div className={styles.ellipsis}>
                                  #{order?._id!.toUpperCase()}
                                </div>
                                {order?.isRefunded! && (
                                  <div className={styles.refunded}>
                                    Refunded
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <Link
                                className={styles.productNameContainer}
                                to={`/products/${order?.product!?._id}`}
                              >
                                <img
                                  src={
                                    order?.product!?.thumbnail ||
                                    "https://dummyimage.com/1200&text=Product+Image"
                                  }
                                />

                                <div className={styles.ellipsis}>
                                  {order?.product!?.name || "Untitled"}
                                </div>
                              </Link>
                            </td>
                            <td style={{ width: "5%" }}>
                              <div className={styles.ellipsis}>
                                <Link
                                  className={
                                    styles.productNameContainer +
                                    " " +
                                    styles.customerNameContainer
                                  }
                                  to={`/customers/${order?.customer!?._id}`}
                                >
                                  <Gravatar
                                    email={order?.customer!.buyerEmail}
                                  />
                                  {order?.customer!.buyerEmail}
                                </Link>
                              </div>
                            </td>
                            <td style={{ width: "10%" }}>
                              <div className={styles.ellipsis}>
                                {order?.taxPercentage!
                                  ? `${order?.taxPercentage!}%`
                                  : "N/A"}
                              </div>
                            </td>
                            <td
                              style={{ width: "10%" }}
                              onClick={() => {
                                navigate(`/orders/${order?._id!}`);
                              }}
                            >
                              <div className={styles.ellipsis}>
                                {formatCurrency(
                                  order?.fee!,
                                  order?.stripeCurrency!?.toUpperCase()
                                )}
                              </div>
                            </td>
                            <td
                              style={{ width: "10%" }}
                              onClick={() => {
                                navigate(`/orders/${order?._id!}`);
                              }}
                            >
                              <div className={styles.ellipsis}>
                                {formatCurrency(
                                  order?.grossAmount!,
                                  order?.stripeCurrency!?.toUpperCase()
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                className={styles.ellipsis}
                                onClick={() => {
                                  navigate(`/orders/${order?._id!}`);
                                }}
                              >
                                {dayjs(order?.createdAt!).fromNow()}
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        const activePrice =
                          order?.stripeSubscription &&
                          order?.stripeSubscription?.items?.data.find(
                            (d: any) => d.plan.active === true
                          );
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => {
                                navigate(`/order-subscription/${order?._id!}`);
                              }}
                            >
                              <div className={styles.orderId}>
                                <div className={styles.ellipsis}>
                                  #{order?._id?.toUpperCase()}
                                </div>
                              </div>
                            </td>
                            <td>
                              <Link
                                className={styles.productNameContainer}
                                to={`/subscriptions/${
                                  order?.subscription!?._id
                                }`}
                              >
                                <img
                                  src={
                                    order?.subscription!?.thumbnail ||
                                    "https://dummyimage.com/1200&text=Subscription+Image"
                                  }
                                />

                                <div className={styles.ellipsis}>
                                  {order?.subscription!?.name || "Untitled"}
                                </div>
                              </Link>
                            </td>
                            <td style={{ width: "5%" }}>
                              <div className={styles.ellipsis}>
                                <div
                                  className={
                                    styles.productNameContainer +
                                    " " +
                                    styles.customerNameContainer
                                  }
                                >
                                  <Gravatar email={order?.buyerEmail!} />
                                  {order?.buyerEmail!}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${styles.status} ${
                                  order?.stripeSubscription! &&
                                  order?.stripeSubscription!.status === "active"
                                    ? styles.active
                                    : ""
                                }`}
                              >
                                {order?.stripeSubscription! &&
                                  order?.stripeSubscription!.status}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {formatCurrency(
                                  activePrice?.price.unit_amount_decimal / 100
                                )}{" "}
                                / {activePrice?.price.recurring.interval}
                              </div>
                            </td>
                            <td>
                              {dayjs(
                                new Date(
                                  order?.stripeSubscription! &&
                                    order?.stripeSubscription!
                                      .current_period_end * 1000
                                )
                              ).fromNow()}
                            </td>
                            <td>{dayjs(order?.createdAt!).fromNow()}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <Count items={orders} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
