import { CheckCircle, Copy, X } from "react-feather";
import crypto from "crypto";
import styles from "./AddWebhook.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FormGroup } from "src/components/FormGroup";
import { TextInput } from "src/components/TextInput";
import { useEffect, useState } from "react";
import { Button, SwitchInput, TextArea } from "src/components";
import Axios from "src/services/api";
import toast from "react-hot-toast";
import { WEBHOOK_INITIAL_DATA } from "./AddWebhook.consts";
import { PageLoader } from "src/components/page-loader";
import { CodeBlock } from "react-code-block";
import { themes } from "prism-react-renderer";
import Select from "react-select";

const AddWebhook = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [secretRevealed, setSecretRevealed] = useState(false);
  const [webhookData, setWebhookData] = useState<any>(WEBHOOK_INITIAL_DATA);
  const [errors, setErrors] = useState<any>({});

  const eventOptions = [
    {
      label: "Order completed",
      value: "order.completed",
      description: "Triggered one-time when an order is completed.",
    },
    {
      label: "Order refunded",
      value: "order.refunded",
      description: "Triggered one-time when an order is refunded.",
    },
    {
      label: "Product created",
      value: "product.created",
      description: "Triggered when a new product is created.",
    },
    {
      label: "Product updated",
      value: "product.updated",
      description: "Triggered when a product is updated.",
    },
    {
      label: "Product deleted",
      value: "product.deleted",
      description: "Triggered when a product is deleted.",
    },
    {
      label: "Review created",
      value: "review.created",
      description: "Triggered when a new review is created.",
    },
    {
      label: "Customer created",
      value: "customer.created",
      description: "Triggered when a new customer is created.",
    },
    {
      label: "Customer subscription created",
      value: "customer.subscription.created",
      description: "Triggered when a new subscription is created.",
    },
    {
      label: "Customer subscription updated",
      value: "customer.subscription.updated",
      description:
        "Triggered when a subscription is updated, such as a plan change.",
    },
    {
      label: "Customer subscription deleted",
      value: "customer.subscription.deleted",
      description: "Triggered when a subscription is canceled.",
    },
    {
      label: "Invoice payment succeeded",
      value: "invoice.payment_succeeded",
      description: "Triggered when a subscription payment succeeds.",
    },
    {
      label: "Invoice payment failed",
      value: "invoice.payment_failed",
      description: "Triggered when a subscription payment fails.",
    },
    {
      label: "Invoice created",
      value: "invoice.created",
      description:
        "Triggered when a new invoice is created for the subscription.",
    },
    {
      label: "Invoice upcoming",
      value: "invoice.upcoming",
      description: "Triggered when an invoice is about to be created.",
    },
    {
      label: "Payment intent succeeded",
      value: "payment_intent.succeeded",
      description:
        "Triggered when the initial payment for a subscription is successfully completed.",
    },
    {
      label: "Payment intent failed",
      value: "payment_intent.payment_failed",
      description:
        "Triggered when the initial payment for a subscription fails.",
    },
    {
      label: "Customer subscription trial will end",
      value: "customer.subscription.trial_will_end",
      description: "Triggered when a subscription trial period is ending.",
    },
    {
      label: "Customer subscription paused",
      value: "customer.subscription.pause",
      description: "Triggered when a subscription is paused.",
    },
    {
      label: "Customer subscription resumed",
      value: "customer.subscription.resumed",
      description: "Triggered when a paused subscription is resumed.",
    },
  ];

  // validate form
  const validateForm = () => {
    let formErrors: any = {};
    if (!webhookData.url) {
      formErrors = { url: "Endpoint URL is required" };
    }
    // validate https url and make sure it's a valid URL
    if (webhookData.url) {
      if (!webhookData.url.startsWith("https://")) {
        formErrors = { url: "URL must start with https://" };
      } else {
        // use regex to check if it's a valid URL
        // not just www but also subdomains
        const urlRegex =
          /^https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

        if (!urlRegex.test(webhookData.url)) {
          formErrors = { url: "Invalid URL" };
        }
      }
    }

    if (webhookData.events?.length === 0) {
      formErrors = { events: "At least one event is required" };
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const submit = () => {
    if (!validateForm()) return;

    if (id) {
      toast.promise(Axios.patch(`/webhooks/${id}`, webhookData), {
        loading: "Updating webhook...",
        success: "Webhook updated successfully",
        error: "Failed to update webhook",
      });
    } else {
      toast.promise(
        Axios.post("/webhooks", webhookData).then(() => {
          navigate("/developers/webhooks");
        }),
        {
          loading: "Creating webhook...",
          success: "Webhook created successfully",
          error: "Failed to create webhook",
        }
      );
    }
  };

  const sendTestWebhook = () => {
    setTestLoading(true);
    Axios.post(`/webhooks/${id}/test`)
      .then(() => {
        toast.success("Test webhook sent successfully");
      })
      .catch(() => {
        toast.error("Failed to send test webhook");
      })
      .finally(() => {
        setTestLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      Axios.get(`/webhooks/${id}`)
        .then((res) => {
          setWebhookData(res.data.webhook);
          setLoading(false);
        })
        .catch(() => {
          toast.error("Failed to fetch webhook");
          setLoading(false);
        });
    }
  }, [id]);

  const topTitle = id ? "Edit webhook" : "Listen to Pocketsflow events";
  const pageTitle = id ? webhookData.url : "Listen to Pocketsflow events";
  const buttonText = id ? "Update webhook" : "Create webhook";

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "4px",
      border: "1px solid #e5e5e5",
      boxShadow: "0px 3px 4px -4px rgba(0, 0, 0, 0.17)",
      ":focus": {
        outline: "none",
        borderColor: "#3447ef",
        boxShadow: "0 0 0 1px #3447ef",
      },
      ":hover": {
        borderColor: "unset !important",
      },
      minHeight: "40px",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#e0e0e0",
      padding: "2px 4px",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "#1a1e26",
      fontWeight: 500,
      fontSize: "11px",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "#333",
      cursor: "pointer",
      marginLeft: "4px",
      ":hover": {
        backgroundColor: "#ff5630",
        color: "white",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "13px",
      color: "#afb7c4",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "white",
      color: state.isSelected ? "#fff" : "#333",
      padding: "10px 15px",
      cursor: "pointer",
      fontSize: "13px",
      ":active": {
        backgroundColor: "#e0e0e0",
      },
    }),
  };

  return loading ? (
    <PageLoader />
  ) : (
    <div className={styles.addWebhookContainer}>
      <div className={styles.left}>
        <div className={styles.nav}>
          <div
            className={styles.closeContainer}
            onClick={() => navigate("/developers/webhooks")}
          >
            <X size={18} />
          </div>
          <div className={styles.divider} />
          <div className={styles.title}>{topTitle}</div>
          <div
            className={
              styles.activeItem +
              (webhookData.active ? " " + styles.active : "")
            }
          >
            <SwitchInput
              checked={webhookData.active}
              onChange={(checked: any) => {
                setWebhookData({ ...webhookData, active: checked });
              }}
              id="active"
              name="active"
            />
            <span>{webhookData.active ? "Enabled" : "Disabled"}</span>
          </div>
          {id && (
            <>
              <div className={`${styles.divider} ${styles.testDivider}`} />
              <Button
                variant="tertiary"
                text={testLoading ? "Sending test..." : "Send test webhook"}
                onClick={sendTestWebhook}
                disabled={testLoading || !webhookData.active}
              />
            </>
          )}
        </div>
        <div className={styles.formContainer}>
          <h2 className={styles.pageTitle}>{pageTitle}</h2>
          {id && (
            <div className={styles.secret}>
              {secretRevealed ? (
                <div
                  className={styles.secretValue}
                  onClick={() => {
                    navigator.clipboard.writeText(webhookData.secret);
                    toast.success("Secret copied to clipboard");
                  }}
                >
                  {webhookData.secret}{" "}
                  <Copy
                    size={15}
                    onClick={() => {
                      navigator.clipboard.writeText(webhookData.secret);
                    }}
                  />
                </div>
              ) : (
                <div
                  className={styles.secretReveal}
                  onClick={() => setSecretRevealed(true)}
                >
                  Reveal secret
                </div>
              )}
            </div>
          )}
          <p className={styles.pageDescription}>
            Setup a webhook endpoint to receive events from Pocketsflow or{" "}
            <a target="_blank" href="https://pocketsflow.com">
              Learn more about webhooks.
            </a>
          </p>
          <div className={styles.webhookFormGrid}>
            <FormGroup label="Endpoint URL" error={errors.url} required>
              <TextInput
                value={webhookData.url}
                name="url"
                placeholder="https://"
                onChange={(e: any) =>
                  setWebhookData({ ...webhookData, url: e.target.value })
                }
                error={errors.url}
              />
            </FormGroup>
            <FormGroup label="Description" error={errors.description}>
              <TextArea
                value={webhookData.description}
                name="description"
                placeholder="An optional description for this webhook"
                onChange={(e: any) =>
                  setWebhookData({
                    ...webhookData,
                    description: e.target.value,
                  })
                }
                error={errors.description}
              />
            </FormGroup>
            <FormGroup label="Events" error={errors.events} required>
              <Select
                isMulti
                options={eventOptions}
                value={eventOptions.filter((option) =>
                  webhookData.events?.includes(option.value)
                )}
                onChange={(selected) => {
                  setWebhookData({
                    ...webhookData,
                    events: selected.map((item) => item.value),
                  });
                }}
                components={{
                  Option: customOption,
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select events"
                styles={customStyles}
              />
            </FormGroup>
            <div className={styles.buttonsContainer}>
              <Button text={buttonText} onClick={submit} />
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => navigate("/developers/webhooks")}
              />
              {id && (
                <div className={styles.deleteButton}>
                  <Button
                    type="button"
                    variant="danger"
                    text="Delete webhook"
                    onClick={() => {
                      if (id) {
                        toast.promise(
                          Axios.delete(`/webhooks/${id}`).then(() => {
                            navigate("/developers/webhooks");
                          }),
                          {
                            loading: "Deleting webhook...",
                            success: "Webhook deleted successfully",
                            error: "Failed to delete webhook",
                          }
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>
            {/* https://akita-expert-satyr.ngrok-free.app/webhooks/got-sale */}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <WebhookExample secret={webhookData.secret || "YOUR_WEBHOOK_SECRET"} />
      </div>
    </div>
  );
};

const WebhookExample = ({ secret }: { secret: string }) => {
  const [language, setLanguage] = useState("node");
  const [copied, setCopied] = useState(false);

  const nodeCode = `
import express from 'express';
import crypto from 'crypto';

const app = express();
app.use(express.json());

// Your webhook secret from Pocketsflow
const WEBHOOK_SECRET = '${secret}';

app.post('/webhooks', (req, res) => {
  // Get signature from headers
  const receivedSignature = req.headers['x-pocketsflow-signature'];
  
  // Generate expected signature
  const expectedSignature = crypto
    .createHmac('sha256', WEBHOOK_SECRET)
    .update(JSON.stringify(req.body))
    .digest('hex');
  
  if (receivedSignature === expectedSignature) {
    // Valid webhook from Pocketsflow
    console.log('Valid webhook received:', req.body);
    res.json({ status: 'success' });
  } else {
    // Invalid signature
    console.error('Invalid webhook signature');
    res.status(401).json({ error: 'Invalid signature' });
  }
});

app.listen(3000, () => {
  console.log('Webhook server listening on port 3000');
});








// Are you reading this? Pocketsflow.
 `;

  const rubyCode = `
require 'sinatra'
require 'json'
require 'openssl'

# Your webhook secret from Pocketsflow
WEBHOOK_SECRET = '${secret}'

# Parse JSON body before processing
before do
  request.body.rewind
  @request_payload = request.body.read
  @parsed_payload = @request_payload.empty? ? {} : JSON.parse(@request_payload)
end

# Webhook endpoint
post '/webhooks' do
  content_type :json
  
  # Get signature from headers
  received_signature = request.env['HTTP_X_POCKETSFLOW_SIGNATURE']
  
  # Generate expected signature
  expected_signature = OpenSSL::HMAC.hexdigest(
    'sha256',
    WEBHOOK_SECRET,
    @request_payload
  )
  
  if received_signature == expected_signature
    # Valid webhook from Pocketsflow
    puts "Valid webhook received: #{@parsed_payload}"
    { status: 'success' }.to_json
  else
    # Invalid signature
    puts 'Invalid webhook signature'
    status 401
    { error: 'Invalid signature' }.to_json
  end
end

# Start server
set :port, 3000







// Are you reading this? Pocketsflow.
 `;

  const pythonCode = `
from flask import Flask, request, jsonify
import hmac
import hashlib
import json

app = Flask(__name__)

# Your webhook secret from Pocketsflow
WEBHOOK_SECRET = '${secret}'

@app.route('/webhooks', methods=['POST'])
def handle_webhook():
    # Get signature from headers
    received_signature = request.headers.get('x-pocketsflow-signature')
    
    # Generate expected signature
    body = request.get_data()
    expected_signature = hmac.new(
        WEBHOOK_SECRET.encode('utf-8'),
        body,
        hashlib.sha256
    ).hexdigest()
    
    if received_signature == expected_signature:
        # Valid webhook from Pocketsflow
        print('Valid webhook received:', request.json)
        return jsonify({'status': 'success'})
    else:
        # Invalid signature
        print('Invalid webhook signature')
        return jsonify({'error': 'Invalid signature'}), 401

if __name__ == '__main__':
    app.run(port=3000)







// Are you reading this? Pocketsflow.
 `;

  const phpCode = `
<?php
// Your webhook secret from Pocketsflow
$webhookSecret = '${secret}';

// Get the raw POST data
$payload = file_get_contents('php://input');

// Get headers
$headers = getallheaders();

// Get signature from headers
$receivedSignature = isset($headers['X-Pocketsflow-Signature']) 
    ? $headers['X-Pocketsflow-Signature'] 
    : '';

// Generate expected signature
$expectedSignature = hash_hmac('sha256', $payload, $webhookSecret);

// Verify signature
if (hash_equals($expectedSignature, $receivedSignature)) {
    // Valid webhook from Pocketsflow
    $data = json_decode($payload, true);
    error_log('Valid webhook received: ' . print_r($data, true));
    
    http_response_code(200);
    echo json_encode(['status' => 'success']);
} else {
    // Invalid signature
    error_log('Invalid webhook signature');
    
    http_response_code(401);
    echo json_encode(['error' => 'Invalid signature']);
}







// Are you reading this? Pocketsflow.
 `;

  const javaCode = `
import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;
import org.springframework.http.ResponseEntity;
import org.springframework.web.bind.annotation.*;
import javax.crypto.Mac;
import javax.crypto.spec.SecretKeySpec;
import org.apache.commons.codec.binary.Hex;
import com.fasterxml.jackson.databind.ObjectMapper;

@SpringBootApplication
@RestController
public class WebhookApplication {

    // Your webhook secret from Pocketsflow
    private static final String WEBHOOK_SECRET = "${secret}";

    public static void main(String[] args) {
        SpringApplication.run(WebhookApplication.class, args);
    }

    @PostMapping("/webhooks")
    public ResponseEntity<?> handleWebhook(
            @RequestBody String payload,
            @RequestHeader("X-Pocketsflow-Signature") String receivedSignature) {
        try {
            // Generate expected signature
            Mac sha256Hmac = Mac.getInstance("HmacSHA256");
            SecretKeySpec secretKey = new SecretKeySpec(
                WEBHOOK_SECRET.getBytes("UTF-8"), 
                "HmacSHA256"
            );
            sha256Hmac.init(secretKey);
            String expectedSignature = Hex.encodeHexString(
                sha256Hmac.doFinal(payload.getBytes("UTF-8"))
            );

            // Verify signature
            if (expectedSignature.equals(receivedSignature)) {
                // Valid webhook from Pocketsflow
                System.out.println("Valid webhook received: " + payload);
                return ResponseEntity.ok()
                    .body(Map.of("status", "success"));
            } else {
                // Invalid signature
                System.err.println("Invalid webhook signature");
                return ResponseEntity.status(401)
                    .body(Map.of("error", "Invalid signature"));
            }

        } catch (Exception e) {
            System.err.println("Error processing webhook: " + e.getMessage());
            return ResponseEntity.status(500)
                .body(Map.of("error", "Internal server error"));
        }
    }
}








// Are you reading this? Pocketsflow.  `;

  const goCode = `
package main

import (
    "crypto/hmac"
    "crypto/sha256"
    "encoding/hex"
    "fmt"
    "io"
    "log"
    "net/http"
)

// Your webhook secret from Pocketsflow
const webhookSecret = "${secret}"

func main() {
    http.HandleFunc("/webhooks", handleWebhook)
    fmt.Println("Webhook server listening on port 3000")
    log.Fatal(http.ListenAndServe(":3000", nil))
}

func handleWebhook(w http.ResponseWriter, r *http.Request) {
    // Only allow POST requests
    if r.Method != "POST" {
        http.Error(w, "Method not allowed", http.StatusMethodNotAllowed)
        return
    }

    // Get signature from headers
    receivedSignature := r.Header.Get("X-Pocketsflow-Signature")
    if receivedSignature == "" {
        http.Error(w, "No signature provided", http.StatusBadRequest)
        return
    }

    // Read the request body
    body, err := io.ReadAll(r.Body)
    if err != nil {
        http.Error(w, "Error reading request body", http.StatusInternalServerError)
        return
    }

    // Generate expected signature
    mac := hmac.New(sha256.New, []byte(webhookSecret))
    mac.Write(body)
    expectedSignature := hex.EncodeToString(mac.Sum(nil))

    // Set response headers
    w.Header().Set("Content-Type", "application/json")

    // Verify signature
    if expectedSignature == receivedSignature {
        // Valid webhook from Pocketsflow
        fmt.Printf("Valid webhook received: %s\n", string(body))
        w.WriteHeader(http.StatusOK)
        fmt.Fprintf(w, \`{"status": "success"}\`)
    } else {
        // Invalid signature
        fmt.Println("Invalid webhook signature")
        w.WriteHeader(http.StatusUnauthorized)
        fmt.Fprintf(w, \`{"error": "Invalid signature"}\`)
    }
}








// Are you reading this? Pocketsflow.  `;

  const csharpCode = `
using Microsoft.AspNetCore.Mvc;
using System.Security.Cryptography;
using System.Text;
using System.Text.Json;

[ApiController]
[Route("[controller]")]
public class WebhooksController : ControllerBase
{
    // Your webhook secret from Pocketsflow
    private const string WebhookSecret = "${secret}";

    [HttpPost]
    public async Task<IActionResult> HandleWebhook()
    {
        try
        {
            // Read the raw request body
            using var reader = new StreamReader(Request.Body);
            var payload = await reader.ReadToEndAsync();

            // Get signature from headers
            var receivedSignature = Request.Headers["X-Pocketsflow-Signature"].ToString();

            if (string.IsNullOrEmpty(receivedSignature))
            {
                return BadRequest(new { error = "No signature provided" });
            }

            // Generate expected signature
            var keyBytes = Encoding.UTF8.GetBytes(WebhookSecret);
            var payloadBytes = Encoding.UTF8.GetBytes(payload);

            using var hmac = new HMACSHA256(keyBytes);
            var hash = hmac.ComputeHash(payloadBytes);
            var expectedSignature = BitConverter
                .ToString(hash)
                .Replace("-", "")
                .ToLower();

            if (expectedSignature == receivedSignature)
            {
                // Valid webhook from Pocketsflow
                Console.WriteLine($"Valid webhook received: {payload}");
                return Ok(new { status = "success" });
            }
            else
            {
                // Invalid signature
                Console.WriteLine("Invalid webhook signature");
                return Unauthorized(new { error = "Invalid signature" });
            }
        }
        catch (Exception ex)
        {
            Console.WriteLine($"Error processing webhook: {ex.Message}");
            return StatusCode(500, new { error = "Internal server error" });
        }
    }
}








// Are you reading this? Pocketsflow.  `;

  // Reset copied state after 3s
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const getCode = () => {
    switch (language) {
      case "node":
        return nodeCode;
      case "python":
        return pythonCode;
      case "php":
        return phpCode;
      case "ruby":
        return rubyCode;
      case "java":
        return javaCode;
      case "csharp":
        return csharpCode;
      case "go":
        return goCode;
      default:
        return "";
    }
  };

  return (
    <>
      <div
        className={styles.copy}
        onClick={() => {
          navigator.clipboard.writeText(getCode() || "");
          setCopied(true);
        }}
      >
        {copied ? <CheckCircle size={15} /> : <Copy size={15} />}
        {copied ? "Copied" : "Copy"}
      </div>
      <div className={styles.codeContainer}>
        <div className={styles.lang}>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="node">Node.js</option>
            <option value="python">Python</option>
            <option value="php">PHP</option>
            <option value="ruby">Ruby</option>
            <option value="java">Java</option>
            <option value="csharp">C#</option>
            <option value="go">Go</option>
          </select>
        </div>
        <CodeBlock
          code={getCode() || ""}
          language="typescript"
          theme={themes.nightOwlLight}
        >
          <CodeBlock.Code className={styles.container}>
            <div className={styles.row}>
              <CodeBlock.LineNumber className={styles.lineNumber} />
              <CodeBlock.LineContent className={styles.lineContent}>
                <CodeBlock.Token />
              </CodeBlock.LineContent>
            </div>
          </CodeBlock.Code>
        </CodeBlock>
      </div>
    </>
  );
};

const customOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} className={styles.optionContainer}>
      <strong>{data.label}</strong>
      <div className={styles.optionDescription}>{data.description}</div>
    </div>
  );
};

export default AddWebhook;
