import React from "react";
import styles from "./SocialSharing.module.scss";
import { Twitter, Facebook, Linkedin } from "react-feather";

interface SocialSharingProps {
  url: string;
  text: string;
}

const SocialSharing: React.FC<SocialSharingProps> = ({ url, text }) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);

  return (
    <div className={styles.socialSharing}>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.socialSharing__link}
      >
        <img
          src="/x-logo.svg"
          style={{
            height: 22,
            width: 22,
          }}
        />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.socialSharing__link}
      >
        <Facebook
          size={22}
          color="#4267B2"
          style={{
            fill: "#4267B2",
          }}
        />
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.socialSharing__link}
      >
        <Linkedin
          size={22}
          color="#0077B5"
          style={{
            fill: "#0077B5",
          }}
        />
      </a>
    </div>
  );
};

export default SocialSharing;
