import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  EmptyState,
  Modal,
  PageTitle,
  SwitchInput,
  TextInput,
} from "src/components";
import styles from "./Subscriptions.module.scss";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Axios from "src/services/api";
import { Subscription } from "./Subscription.types";
import { makeCdnUrl } from "src/services/cdn";
import { formatCurrency } from "src/services/currency";
import dayjs from "dayjs";
import usePopups from "src/services/usePopups";
import { Count } from "../Customers/Customers";
import { AiOutlineDelete } from "react-icons/ai";
import { IoAlertCircle } from "react-icons/io5";
import { PageLoader } from "src/components/page-loader";

const columns = [
  "Name",
  "Price",
  "Created at",
  "View subscription",
  "Published",
  "",
];

const Subscriptions = () => {
  const navigate = useNavigate();
  const { popups, togglePopup } = usePopups([
    "bankAlert",
    "deleteSubscription",
  ]);
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [id, setId] = useState<string>("");
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<
    Subscription[]
  >([]);

  useEffect(() => {
    toast.promise(
      Axios.get<Subscription[]>("/subscriptions").then((response) => {
        setSubscriptions(response.data);
        setLoading(false);
      }),
      {
        loading: "Loading products...",
        success: "Loaded!",
        error: "Error while loading.",
      }
    );
  }, [reload]);

  useEffect(() => {
    if (search === "") {
      setFilteredSubscriptions(subscriptions);
    } else {
      setFilteredSubscriptions(
        subscriptions.filter((subscription) =>
          subscription.name.includes(search)
        )
      );
    }
  }, [search, subscriptions]);

  const [stripeAccount, setStripeAccount] = useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const hasBankAccount = stripeAccount?.external_accounts?.data.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  if (loading) return <PageLoader />;

  const frequencyFormatter = (freq: string) => {
    if (freq === "monthly") {
      return "month";
    }
    if (freq === "weekly") {
      return "week";
    }
    if (freq === "yearly") {
      return "year";
    }
    return "";
  };

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <Link to="/subscriptions/new">
            <Button text="New subscription" />
          </Link>
        }
      >
        Subscriptions
      </PageTitle>

      <div className={styles.learnMore}>
        <div className={styles.learnMoreText}>
          Subscriptions are a new feature, learn more about how to use them
          here:{" "}
          <Link
            to="https://help.pocketsflow.com/en/articles/10516902-subscriptions"
            target="_blank"
          >
            Subscriptions docs
          </Link>
        </div>
      </div>

      {filteredSubscriptions?.length === 0 && search === "" ? (
        <EmptyState
          loading={loading}
          title="Create your first subscription"
          subTitle="Create your first subscription and start selling it to your customers."
          buttonText="Create subscription"
          buttonClick={() => {
            navigate("/subscriptions/new");
          }}
        />
      ) : (
        <>
          <div className={styles.seachInput}>
            <TextInput
              name="search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              placeholder="Search subscriptions"
            />
          </div>
          {filteredSubscriptions?.length === 0 && search !== "" ? (
            <EmptyState
              loading={loading}
              title="No subscriptions found"
              subTitle="No subscriptions found with this query, change your search query to find subscriptions."
            />
          ) : (
            <>
              <div className={styles.tableContainer}>
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSubscriptions?.map((subscription, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ width: "40%" }}
                            onClick={() =>
                              navigate(`/subscriptions/${subscription._id}`)
                            }
                          >
                            <div className={styles.productNameContainer}>
                              <img
                                src={
                                  subscription.thumbnail
                                    ? makeCdnUrl(subscription.thumbnail)
                                    : "https://dummyimage.com/1200&text=Product+Image"
                                }
                              />
                              <div className={styles.productName}>
                                {subscription.name}
                              </div>
                            </div>
                          </td>
                          {/* <td style={{ width: "20%" }}>
                      {numeral(product.sales).format("0[.]00")}
                    </td> */}
                          <td
                            style={{ width: "10%" }}
                            onClick={() =>
                              navigate(`/subscriptions/${subscription._id}`)
                            }
                          >
                            <div style={{ whiteSpace: "nowrap" }}>
                              {formatCurrency(subscription.price)} /{" "}
                              {frequencyFormatter(subscription.frequency)}
                            </div>
                          </td>
                          {/* <td style={{ width: "10%" }}>
                      {formatCurrency(product.today)}
                    </td>
                    <td style={{ width: "10%" }}>
                      {formatCurrency(product.week)}
                    </td>
                    <td style={{ width: "10%" }}>
                      {formatCurrency(product.allTime)}
                    </td> */}
                          <td style={{ width: "20%" }}>
                            {dayjs(subscription.createdAt).fromNow()}
                          </td>
                          <td style={{ width: "20%" }}>
                            {!hasBankAccount &&
                            !hasPayPal &&
                            subscription.price !== 0 ? (
                              <div
                                onClick={() => {
                                  if (!hasBankAccount && !hasPayPal)
                                    togglePopup("bankAlert");
                                }}
                              >
                                <a target="_blank">
                                  <Button variant="tertiary" text="View" />
                                </a>
                              </div>
                            ) : (
                              <a
                                href={`https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${subscription._id}`}
                                target="_blank"
                              >
                                <Button variant="tertiary" text="View" />
                              </a>
                            )}
                          </td>
                          <td
                            style={{ width: "30%" }}
                            // style={{ color: product.published ? "#1ecc1e" : "red" }}
                            onClick={(e) => {
                              console.log(subscription._id);
                            }}
                          >
                            {/* {product.published ? "Published" : "Unpublished"} */}
                            <div className={styles.switch}>
                              <span>
                                {subscription.published ? "Yes" : "No"}
                              </span>
                              <SwitchInput
                                checked={
                                  !hasBankAccount &&
                                  !hasPayPal &&
                                  subscription.price !== 0
                                    ? false
                                    : subscription.published
                                }
                                onChange={(checked: boolean) => {
                                  if (
                                    !hasBankAccount &&
                                    !hasPayPal &&
                                    subscription.price !== 0
                                  ) {
                                    togglePopup("bankAlert");
                                    return;
                                  }

                                  toast.promise(
                                    Axios.post(
                                      `/subscriptions/${subscription._id}/published`,
                                      {
                                        published: checked,
                                      }
                                    ).then(() => {
                                      setReload(!reload);
                                    }),
                                    {
                                      loading: "Publishing product",
                                      success: checked
                                        ? "Product published"
                                        : "Product unpublished",
                                      error: "Error publishing product",
                                    }
                                  );
                                }}
                                id={"published" + subscription._id}
                                name={"published" + subscription._id}
                              />
                            </div>
                          </td>
                          <td style={{ paddingLeft: "20px" }}>
                            <Button
                              isIcon
                              variant="secondary"
                              text={
                                <AiOutlineDelete
                                  size={18}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              }
                              onClick={() => {
                                togglePopup("deleteSubscription");
                                setId(subscription._id);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Count items={filteredSubscriptions} />
            </>
          )}
        </>
      )}
      {popups.deleteSubscription && (
        <Modal
          onClose={() => togglePopup("deleteSubscription")}
          title="Delete Subscription"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteSubscription");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/subscriptions/" + id)
                .then(() => {
                  togglePopup("deleteSubscription");
                  toast.success("Subscription deleted!");
                  navigate("/subscriptions");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteSubscription");
                  toast.error("Failed to delete subscription");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this subscription?
          </div>
        </Modal>
      )}
      {popups.bankAlert && (
        <Modal
          onClose={() => togglePopup("bankAlert")}
          title="Connect a Payment Method"
          footerRightButton1={{
            // @ts-ignore
            label: "Cancel",
            onClick: () => {
              togglePopup("bankAlert");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Connect Bank Account or PayPal",
            onClick: () => {
              navigate("/profile/payments");
            },
          }}
        >
          <div className={styles.bankModalText}>
            <IoAlertCircle fontSize={24} className={styles.bankModalIcon} />
            Your subscription isn't free, so to start making sales you need to
            connect either your bank account or PayPal so you can get paid out.
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Subscriptions;
