import React, { useEffect, useState } from "react";
import { Button, Card, PageTitle } from "src/components";
import styles from "./Webhooks.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Globe } from "react-feather";

const Webhooks = () => {
  const navigate = useNavigate();
  const toggleAddWebhook = () => {
    navigate("/developers/webhooks/add");
  };

  const [webhooks, setWebhooks] = useState<any>([]);

  useEffect(() => {
    Axios.get("/webhooks").then((res) => {
      setWebhooks(res.data.webhooks);
    });
  }, []);

  // const fireWebhook = () => {
  //   Axios.post("/webhooks/send", {
  //     event: "order.completed",
  //     data: {
  //       productId: "444",
  //     },
  //   }).then((res) => {
  //     console.log(res);
  //   });
  // };

  return (
    <div className={styles.settingsPage}>
      <PageTitle
        actions={
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              text="Webhook docs"
              variant="tertiary"
              onClick={() =>
                window.open(
                  "https://help.pocketsflow.com/en/articles/10516809-webhooks",
                  "_blank"
                )
              }
            />
            {/* <Button text={"Fire webhook"} onClick={fireWebhook} /> */}
            <Button text={"New endpoint"} onClick={toggleAddWebhook} />
          </div>
        }
      >
        Webhooks
      </PageTitle>
      <Card>
        <h3 className={styles.cardTitle}>Webhook endpoints</h3>
        {webhooks.length === 0 ? (
          <div className={styles.emptyState}>
            <p>No webhooks found</p>
          </div>
        ) : (
          <div className={styles.itemsList}>
            <table>
              <thead>
                <tr>
                  <th>URL</th>
                  <th>Events</th>
                  <th>Created at</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {webhooks?.map((webhook: any) => (
                  <tr>
                    <td>
                      <Link
                        className={styles.webhookUrl}
                        to={`/developers/webhooks/${webhook._id}`}
                      >
                        <Globe size={16} style={{ marginTop: 2 }} />
                        {webhook.url}
                      </Link>
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/developers/webhooks/${webhook._id}`)
                      }
                    >
                      {webhook.events.length === 1
                        ? webhook.events[0]
                        : webhook.events.length + " events"}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/developers/webhooks/${webhook._id}`)
                      }
                    >
                      {dayjs(webhook.createdAt).fromNow()}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/developers/webhooks/${webhook._id}`)
                      }
                    >
                      <div
                        className={`${styles.status} ${
                          webhook.active ? styles.active : ""
                        }`}
                      >
                        {webhook.active ? "Enabled" : "Disabled"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Webhooks;
